import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import JobCard from './JobCard'
import { useUserType } from '@utils/common'
import { jobSaveRequest } from '@features/jobs/jobSlice'
import PageEmptyJob from './PageEmptyJob'
import PageEmptyJobCandidate from './PageEmptyJobCandidate'
import UILoading from '@components/UILoading'

const PageJobList = ({ myJobs }) => {
  const userType = useUserType()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleSave = (job, save) => {
    console.log('[dev] PageJobList::handleSave::job', job, save)
    dispatch(
      jobSaveRequest({
        job,
        save
      })
    )
  }
  // if (isLoading) {
  //   return <UILoading />;
  // }
  const isCompany = userType === 'company' || userType === 'employee'
  if (myJobs?.length === 0) {
    if (isCompany) {
      return <PageEmptyJob />
    }
    return <PageEmptyJobCandidate />
  }

  return (
    <Box
      sx={{
        //
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {myJobs.map(job => (
        <JobCard
          key={job?.id}
          isCompany={isCompany}
          navigate={navigate}
          onSave={handleSave}
          {...job}
        />
      ))}
    </Box>
  )
}

export default PageJobList
