import { apiFetch } from '@utils/apiFetch'

const authAPI = apiFetch

// curl --location --request GET 'https://ideapros-llc-lander-30616.botics.co/api/v1/dropdowns/categories/' \
// --header 'Authorization: Token 37a051bd81387111fedc1d9c12ef720e3e19ac95'

export function apiJobCategoryRequest (payload) {
  return authAPI.get(`/api/v1/dropdowns/categories/`)
}

// curl --location --request GET 'https://ideapros-llc-lander-30616.botics.co/api/v1/jobs/?visible=True' \
// --header 'Authorization: Token b0e668933ec3545f6f4810e2f685e5fdba05203d'

export function apiJobRequest (isVisible = true) {
  return authAPI.get(`/api/v1/jobs/${isVisible ? '?visible=True' : ''}`)
}

// curl --location --request GET 'https://ideapros-llc-lander-30616.botics.co/api/v1/jobs/11/' \
// --header 'Authorization: Token 6c3a12c373337cf312579e26b224118839d206df'
export function apiJobDetailRequest (payload) {
  return authAPI.get(`/api/v1/jobs/${payload}/`)
}

export function apiJobSavedRequest (payload) {
  return authAPI.get(`/api/v1/jobs/saved/`)
}

// curl --location --request DELETE 'https://ideapros-llc-lander-30616.botics.co/api/v1/jobs/6/' \
// --header 'Authorization: Token aba8e4711b1683fa77b1277c19a999ff868eb29f'
export function apiJobRemoveRequest (payload) {
  return authAPI.delete(`/api/v1/jobs/${payload}/`)
}

// curl --location --request POST 'https://ideapros-llc-lander-30616.botics.co/api/v1/jobs/saved/' \
// --header 'Authorization: Token 90a16d716864ff48490b1c7ba3e69b220d338cd2' \
// --form 'job="9"'
export function apiJobSaveRequest (payload) {
  const { save, ...body } = payload
  if (save) {
    return authAPI.post(`/api/v1/jobs/saved/`, body)
  }
  return authAPI.delete(`/api/v1/jobs/saved/`, body)
}

// curl --location --request POST 'https://ideapros-llc-lander-30616.botics.co/api/v1/jobs/apply/' \
// --header 'Authorization: Token 95ddfe3d73fa9a3f5858e5aaa9fe04b5ac70529a' \
// --form 'job="9"'
export function apiJobApplyRequest (payload) {
  return authAPI.post(`/api/v1/jobs/apply/`, payload)
}

// curl --location --request POST 'https://ideapros-llc-lander-30616.botics.co/api/v1/jobs/' \
// --header 'Authorization: Token 6c3a12c373337cf312579e26b224118839d206df' \
// --form 'looking_for="Business"' \
// --form 'role="Accounting"' \
// --form 'name="Need an Accountant for Crowdbotics Inc."' \
// --form 'description="Financial analysis refers to an assessment of the viability, stabilty and profitability of a business, sub-business or project"' \
// --form 'state="Nevada"' \
// --form 'zip_code="10001"' \
// --form 'city="Las Vegas"' \
// --form 'salary_type="Annual"' \
// --form 'budget="115000"' \
// --form 'negotiated="True"' \
// --form 'estimated_time="2 weeks"' \
// --form 'visible="True"' \
// --form 'image_1=@"/C:/Users/NEXT/Desktop/works.png"' \
// --form 'image_2=@"/C:/Users/NEXT/Desktop/canceled.png"' \
// --form 'image_3=@"/C:/Users/NEXT/Desktop/worked.png"'

export function apiJobCreateRequest (payload) {
  return authAPI.post(`/api/v1/jobs/`, payload, 'none')
}

export function apiJobUpdateRequest (id, payload) {
  return authAPI.patch(`/api/v1/jobs/${id}/`, payload, 'none')
}

const mockedResult = {
  id: 1,
  company: {
    id: 13,
    first_name: 'Mark',
    last_name: 'Zuckerberg',
    email: 'comp3@crowdbotics.com',
    type: 'Company',
    registration_id: null,
    company: {
      business_name: 'Meta',
      contact_number: '2893829329',
      business_type: 'Marketing',
      position: 'CEO',
      payment_method: '',
      photo:
        'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/profile/photos/mark-zuck-img_WQvsglJ.jpeg',
      city: 'San Francisco',
      zip_code: '92382',
      state: 'California'
    },
    job_post_notifications: true,
    interview_notifications: true,
    message_notifications: true
  },
  looking_for: 'Communications',
  role: 'Journalism',
  files: [
    {
      id: 1,
      file:
        'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/jobs/files/logan-weaver-Isle-8YpbIQ-unsplash_1.png'
    },
    {
      id: 2,
      file:
        'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/jobs/files/mark-zuck-img.jpeg'
    },
    {
      id: 3,
      file:
        'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/jobs/files/pexels-photo-220453.jpeg'
    },
    {
      id: 4,
      file:
        'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/jobs/files/pexels-photo-532220.jpeg'
    },
    {
      id: 5,
      file:
        'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/jobs/files/pexels-photo-771742.jpeg'
    }
  ],
  posted: 'Posted 0 day(s) ago',
  applied_candidates: [],
  number_of_candidates: 0,
  applied: null,
  saved: null,
  name: 'Editor',
  description:
    'Is your writing compelling, crisp and colorful? Can you interview multiple news sources and write an article that is organized, balanced, informative and a good read, and then promote it on social media? Do you know the difference between Bibi and Buber? So this is the job for you.',
  state: null,
  zip_code: null,
  city: null,
  salary_type: 'Annual',
  budget: 150000,
  negotiated: true,
  estimated_time: '1 year',
  visible: false,
  created_at: '2022-01-13',
  views: 0
}
