import * as React from 'react'
import UIButton, { FilterButton } from '@components/UIButton'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import WrapperContent from '@components/WrapperContent'
import {
  candidateSaveRequest,
  updateCandidateList,
  selectCandidates
} from '@features/candidates/candidateSlice'
import { ReactComponent as LocationIcon } from '@assets/icons/location.svg'
import { ReactComponent as BookmarkIcon } from '@assets/icons/bookmark.svg'
import { ReactComponent as Bookmark2Icon } from '@assets/icons/bookmarkOutlined.svg'
import { ReactComponent as FilterIcon } from '@assets/icons/filter.svg'
import { useMenu } from '@utils/common'
import {
  selectCurrentUser,
  selectIndustries,
  selectPositions,
  selectUser,
  selectUserProfile,
  currentProfileRequest
} from '@features/auth/authSlice'
import { selectCategories } from '@features/jobs/jobSlice'
import UITextField, { UITextFieldMask } from '@components/UITextField'
import { STATE_TYPES } from '@utils/phoneMask'
import UIMenu from '@components/UIMenu'
import { Autocomplete, MenuItem, TextField } from '@mui/material'

function LinkTab (props) {
  return (
    <Tab
      component='a'
      onClick={event => {
        event.preventDefault()
      }}
      sx={{
        fontWeight: '500',
        fontSize: '1.5rem', // 24px
        textTransform: 'none'
      }}
      {...props}
    />
  )
}

const UIButtonCard = ({ sx, ...props }) => (
  <UIButton
    sx={{
      //
      // width: '334px',
      width: '100%',
      py: 1,
      fontSize: '0.75rem', //12px
      color: 'white',
      ...sx
    }}
    {...props}
  />
)

const CandidateCard = ({
  //
  id,
  first_name,
  last_name,
  candidate,
  saved,
  onSave = () => {},
  navigate,
  userProfile
}) => {
  const [isSaved, setIsSaved] = React.useState(saved)
  return (
    <Box
      sx={{
        m: 1,
        p: 1,
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)',
        borderRadius: '10px'
      }}
    >
      <Box
        sx={{
          // m: 1
          position: 'relative'
        }}
      >
        <Avatar
          alt={first_name}
          src={candidate?.photo}
          variant='rounded'
          sx={{
            //
            width: '238px',
            height: '180px',
            borderRadius: '12px'
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0
          }}
        >
          <IconButton
            sx={{
              backgroundColor: 'white',
              m: 1
            }}
            onClick={() => {
              onSave(id, !isSaved)
              setIsSaved(!isSaved)
            }}
          >
            {isSaved ? (
              <BookmarkIcon fill='black' height={18} width={18} />
            ) : (
              <Bookmark2Icon height={18} width={18} />
            )}
          </IconButton>
        </Box>
      </Box>
      <Typography
        sx={{
          pt: 1,
          fontSize: '1.25rem', // 20px
          fontWeight: '500'
        }}
      >{`${first_name} ${last_name}`}</Typography>
      <Typography
        sx={{
          fontSize: '0.875rem', // 20px
          fontWeight: '400',
          color: 'rgba(0,0,0,0.5)'
        }}
      >
        {candidate?.current_company_position || 'Unemployed'}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <UIButtonCard
            color='primary'
            disabled={
              userProfile?.subscribed == 'canceled' ||
              !userProfile?.subscription
            }
            onClick={() => {
              navigate(`/app/chat/${id}`)
            }}
          >
            Message
          </UIButtonCard>
        </Grid>
        <Grid item xs={6}>
          <UIButtonCard
            disabled={
              userProfile?.subscribed == 'canceled' ||
              !userProfile?.subscription
            }
            onClick={() => {
              navigate(`/app/profile/${id}`)
            }}
          >
            View Profile{userProfile?.subscribed}
          </UIButtonCard>
        </Grid>
      </Grid>
    </Box>
  )
}

const PageCandidates = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const [section, setSection] = React.useState('active');
  // const isSaved = !!useMatch({ path: '/app/candidates/saved', end: true });
  const { section = 'active' } = useParams()
  const isSaved = section === 'saved'

  const [el, handleOpenMenu] = useMenu()

  const candidates = useSelector(selectCandidates(section))
  const positions = useSelector(selectPositions)
  const industries = useSelector(selectIndustries)
  const categories = useSelector(selectCategories)
  const userProfile = useSelector(selectCurrentUser)
  const user = useSelector(selectUser);
  const [interestsMap, setInterestsMap] = React.useState([])
  const [preferedLocations, setPreferedLocations] = React.useState([])
  const [inputValue, setInputValue] = React.useState('')
  React.useEffect(() => {
    dispatch(currentProfileRequest(user?.id))
    dispatch(
      updateCandidateList({
        candidateType: section,
        // interestsMap,
        preferedLocations
      })
    )
  }, [dispatch, section, preferedLocations])
  React.useEffect(() => {
    console.log('[dev] updateFilter: ', interestsMap, preferedLocations)
  }, [interestsMap, preferedLocations])
  const settings = [
    {
      menuItem: (
        <MenuItem
          key={`prefered-locations`}
          sx={{
            width: '300px'
          }}
        >
          {/* <Autocomplete
            multiple
            freeSolo
            sx={{ width: '100%' }}
            id="prefered-locations"
            options={[]}
            value={preferedLocations}
            inputValue={inputValue}
            onChange={(event, newValue) => {
              setPreferedLocations(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              const options = newInputValue.split(',');

              if (options.length > 1) {
                setPreferedLocations(
                  preferedLocations
                    .concat(options)
                    .map((x) => x.trim())
                    .filter((x) => x)
                );

                setInputValue('');
              } else {
                setInputValue(newInputValue);
                // Sorry the delay, I am participating on a conference all the week, so I am out of my work place for some parts of the day
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Prefered Locations"
                placeholder="Use , as a delimiter"
              />
            )}
          /> */}
          <UITextField
            id='state'
            label='State'
            multiple
            placeholder='Enter your state'
            icon={<LocationIcon />}
            sx={{ gridColumn: 'span 2' }}
            onChange={value => {
              setPreferedLocations(
                typeof value === 'string' ? value.split(',') : value
              )
            }}
            value={preferedLocations}
            // error={errors?.state}
            select
          >
            <MenuItem value=''>
              <em>Select your state</em>
            </MenuItem>
            {STATE_TYPES.map((state, index) => (
              <MenuItem key={index} value={state}>
                {state}
              </MenuItem>
            ))}
          </UITextField>
        </MenuItem>
      )
    }
    // {
    //   menuItem: (
    //     <MenuItem
    //       key={`interest`}
    //       sx={{
    //         width: '300px',
    //       }}
    //     >
    //       <Autocomplete
    //         multiple
    //         id="tags-standard"
    //         sx={{ width: '100%' }}
    //         options={categories?.map((c) => c.name)}
    //         getOptionLabel={(option) => option}
    //         // defaultValue={[top100Films[0]]}
    //         // value={interestsMap}
    //         onChange={(event, newValue) => {
    //           setInterestsMap(newValue);
    //         }}
    //         renderInput={(params) => (
    //           <TextField
    //             {...params}
    //             // variant="standard"
    //             label="Interested In"
    //             placeholder="Interest"
    //           />
    //         )}
    //       />
    //     </MenuItem>
    //   ),
    // },

    // {
    //   label: 'Account Settings',
    //   action: () => {
    //     navigate('/app/settings');
    //   },
    // },
    // {
    //   label: 'Log Out',
    //   action: () => {},
    // },
  ]
  React.useEffect(() => {
    dispatch(
      updateCandidateList({
        candidateType: section,
        // interestsMap,
        preferedLocations
      })
    )
  }, [dispatch, section, interestsMap, preferedLocations])

  // React.useEffect(() => {
  //   if (isSaved) {
  //     setSection('saved');
  //   } else {
  //     setSection('active');
  //   }
  //   console.log('[dev] isSaved::', isSaved);
  // }, [isSaved]);

  const handleChangeSection = (event, newValue) => {
    const mapSectionToURL = {
      interested: '/app/candidates/interested',
      active: '/app/candidates'
    }
    navigate(mapSectionToURL[newValue])
    // setValue(newValue);
  }

  const handleSave = (candidate, save) => {
    console.log('[dev] PageCandidates::handleSave::candidate', candidate)
    dispatch(
      candidateSaveRequest({
        candidate,
        save
      })
    )
  }

  return (
    <WrapperContent
      label={
        section === 'saved' ? 'Saved' : 'Candidates'
        // : [
        //     {
        //       label: 'Candidates',
        //       href: '/app/candidates',
        //     },
        //     { label: 'Interested', href: '/app/candidates/interested' },
        //   ]
      }
      sx={{
        dispaly: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      actions={
        <FilterButton onClick={handleOpenMenu} startIcon={<FilterIcon />}>
          Filter
        </FilterButton>
      }
      actionsCenter={
        !isSaved && (
          <Tabs
            value={section}
            onChange={handleChangeSection}
            aria-label='nav tabs example'
          >
            <LinkTab label='Candidates' href='/app/candidates' value='active' />
            <LinkTab
              label='Interested'
              href='/app/candidates/interested'
              value='interested'
            />
          </Tabs>
        )
      }
    >
      <UIMenu el={el} listItem={settings} />
      <Box
        sx={{
          //
          flexGrow: 1,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}
      >
        {candidates.map(c => (
          <CandidateCard
            key={c.id}
            userProfile={userProfile}
            onSave={handleSave}
            navigate={navigate}
            {...c}
          />
        ))}
      </Box>

      {/* </Grid> */}
    </WrapperContent>
  )
}
export default PageCandidates
