import * as React from 'react';
import Paper from '@mui/material/Paper';

const UICard = React.forwardRef(({ children, sx, props }, ref) => (
  <Paper
    ref={ref}
    elevation={0}
    sx={{
      borderRadius: '15px',
      ...sx,
    }}
    {...props}
  >
    {children}
  </Paper>
));

export default UICard;
