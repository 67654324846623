import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiCandidateRequest, apiCandidateSaveRequest } from './candidateAPI';

const initialState = {
  status: 'idle',
  list: {
    active: [],
    interested: [],
    saved: [],
  },
  cfilter: {
    candidateType: 'active',
    interests: '',
    preferedLocations: [],
  },
};

export const candidatesRequest = createAsyncThunk(
  'candidate/candidatesRequest',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth;
      const { cfilter } = getState().candidate;
      updateCFilter();
      const { data, status } = await apiCandidateRequest({
        ...cfilter,
        ...payload,
      })(token);
      const { candidateType } = cfilter;

      if (status === 201 || status === 200) {
        if (candidateType === 'interested') {
          return {
            data: data?.map(({ user }) => user),
            candidateType: payload?.candidateType,
          };
        }
        return { data, candidateType };
      }
      return rejectWithValue(data);
    } catch (err) {
      console.log('[dev] candidate/candidatesRequest::response::catch', err);
    }
  }
);

export const candidateSaveRequest = createAsyncThunk(
  'candidate/candidateSaveRequest',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth;
      const { data, status } = await apiCandidateSaveRequest(payload)(token);

      if (status === 201 || status === 200) {
        return data;
      }
      return rejectWithValue(data);
    } catch (err) {
      console.log('[dev] candidate/candidateSaveRequest::response::catch', err);
    }
  }
);

export const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    updateCFilter: (state, action) => {
      // state.newJob = { ...state.newJob, ...action.payload };
      state.cfilter = { ...state.cfilter, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(candidatesRequest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(candidatesRequest.fulfilled, (state, action) => {
        const { data, candidateType } = action.payload;
        state.list[candidateType] = data;
        state.status = 'idle';
      })
      .addCase(candidateSaveRequest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(candidateSaveRequest.fulfilled, (state, action) => {
        state.status = 'idle';
      });
  },
});

export const { updateCFilter } = candidateSlice.actions;

export const selectCandidates = (candidateType) => (state) =>
  state.candidate.list[candidateType];
export const selectCFilter = (state) => state.candidate.cfilter;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const updateCandidateList = (cfilter) => (dispatch, getState) => {
  dispatch(updateCFilter(cfilter));
  dispatch(candidatesRequest(cfilter));
};

export default candidateSlice.reducer;
