import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  apigetEmployee,
  apiaddEmployee,
  apiupdateEmployee,
  apigetEmployeeByID,
  apideleteEmployee
} from './employeeAPI'

const initialState = {
  status: 'idle',
  plans: [],
  allEmployees: []
}

export const getEmployee = createAsyncThunk(
  'employee/getEmployee',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apigetEmployee()(token)

      if (status === 201 || status === 200) {
        console.log('getEmployeedata', data)
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] employee/getEmployee::response::catch', err)
    }
  }
)

export const getEmployeeByID = createAsyncThunk(
  'employee/getEmployeeByID',
  async (id, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apigetEmployeeByID(id)(token)

      if (status === 201 || status === 200) {
        console.log('getEmployeedata', data)
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] employee/getEmployee::response::catch', err)
    }
  }
)

export const addEmployee = createAsyncThunk(
  'employee/addEmployee',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiaddEmployee(payload)(token)

      if (status === 201 || status === 200) {
        console.log('addEmployeedata', data)
        return { data, status }
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] employee/getEmployee::response::catch', err)
    }
  }
)

export const updateEmployee = createAsyncThunk(
  'employee/updateEmployee',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { id, body } = payload
      const { data, status } = await apiupdateEmployee(id, body)(token)

      if (status === 201 || status === 200) {
        console.log('updateEmployee', data)
        return { data, status }
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] employee/getEmployee::response::catch', err)
    }
  }
)

export const _deleteEmployee = createAsyncThunk(
  'employee/_deleteEmployee',
  async (id, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apideleteEmployee(id)(token)

      if (status === 201 || status === 200 || status === 204) {
        console.log('getPaymentMethoddata', data)
        return { data, status }
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] employee/_deleteEmployee::response::catch', err)
    }
  }
)

export const _subscribePlan = createAsyncThunk(
  'employee/_subscribePlan',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await subscribePlan(payload)(token)

      if (status === 201 || status === 200) {
        console.log('_subscribePlan', data)
        alert(`You have successfully subscribed!`)
        return { data, status }
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] employee/getEmployee::response::catch', err)
    }
  }
)

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getEmployee.pending, state => {
        state.status = 'loading'
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.allEmployees = action.payload
        state.status = 'idle'
      })
      .addCase(updateEmployee.pending, state => {
        state.status = 'loading'
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        state.paymentMethod = action.payload
        state.status = 'idle'
      })
      .addCase(addEmployee.pending, state => {
        state.status = 'loading'
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.paymentMethod = action.payload
        state.status = 'idle'
      })
      .addCase(getEmployeeByID.pending, state => {
        state.status = 'loading'
      })
      .addCase(getEmployeeByID.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(_deleteEmployee.pending, state => {
        state.status = 'loading'
      })
      .addCase(_deleteEmployee.fulfilled, (state, action) => {
        state.status = 'idle'
      })
  }
})

// export const {  } = employeeSlice.actions

export const selectedPlans = state => state.employee.plans
export const selectedAllEmployees = state => state.employee.allEmployees

export default employeeSlice.reducer
