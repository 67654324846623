import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Grow from '@mui/material/Grow';
import { UploadButton } from './UIButton';

import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';

const FilePreview = ({ file, onRemove, editMode }) => {
  const [showFile, setShowFile] = React.useState(true);
  const isString = typeof file === 'string';
  return (
    <Grow in={showFile}>
      <Avatar
        onClick={() => {
          if (editMode) {
            setShowFile(false);
            onRemove(file.name);
          }
        }}
        // component="button"
        sx={{
          width: '108px',
          height: '108px',
          backgroundColor: 'rgba(0, 0, 0, 0.06)',
          borderRadius: '8px',
          mr: 2,
          cursor: 'pointer',
          ...((editMode && {
            ':hover': {
              '&:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundColor: 'rgba(0,0,0,0.7)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                  '#fff'
                )}" d="M16.9059 4.36849C17.2301 4.36849 17.5 4.63765 17.5 4.98015V5.29681C17.5 5.63098 17.2301 5.90847 16.9059 5.90847H3.09488C2.76988 5.90847 2.5 5.63098 2.5 5.29681V4.98015C2.5 4.63765 2.76988 4.36849 3.09488 4.36849H5.52464C6.01821 4.36849 6.44775 4.01766 6.55879 3.52266L6.68603 2.95434C6.88378 2.18018 7.53458 1.66602 8.27939 1.66602H11.7206C12.4573 1.66602 13.1154 2.18018 13.3059 2.9135L13.442 3.52183C13.5522 4.01766 13.9818 4.36849 14.4762 4.36849H16.9059ZM15.6715 15.9444C15.9252 13.5803 16.3693 7.96365 16.3693 7.90699C16.3855 7.73532 16.3296 7.57282 16.2186 7.44199C16.0994 7.31949 15.9487 7.24699 15.7826 7.24699H4.22377C4.05682 7.24699 3.89797 7.31949 3.78774 7.44199C3.6759 7.57282 3.62079 7.73532 3.62889 7.90699C3.63038 7.9174 3.64632 8.11524 3.67296 8.446C3.79132 9.91537 4.12098 14.0079 4.33399 15.9444C4.48474 17.3711 5.42082 18.2677 6.77672 18.3002C7.82302 18.3244 8.90094 18.3327 10.0032 18.3327C11.0414 18.3327 12.0958 18.3244 13.1745 18.3002C14.5774 18.2761 15.5127 17.3952 15.6715 15.9444Z"/></svg>')`,
              },
            },
          }) ||
            {}),
        }}
        variant="rounded"
        src={(isString && file) || URL.createObjectURL(file)}
      ></Avatar>
    </Grow>
  );
};

// {
//   id: 9,
//   file: 'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/jobs/files/pexels-photo-771742_6tF75O7.jpeg'
// }
function UIFiles({ files, setFiles, editMode = false }) {
  const handleRemoveFile = (name) => {
    console.log('[dev] remove file::', name);
    const { [name]: removedFile, ...newFiles } = files;
    // setFiles((f) => f.filter((file) => file.name !== name));
    setFiles(newFiles);
  };
  const selectFiles = (event) => {
    const newFiles = event?.target?.files;
    console.log('[dev] newFiles', newFiles);
    // const fileList = [];
    const fileList = { ...files };
    if (newFiles && newFiles.length > 0) {
      const m = newFiles.length;
      for (let i = 0; Object.keys(fileList).length < 5 && i < m; i += 1) {
        // fileList.push(newFiles.item(i));
        const f = newFiles.item(i);
        fileList[f.name] = f;
        console.log('[dev] add f', Object.keys(fileList).length);
      }
      // setFiles([...files, ...fileList]);
      setFiles(fileList);
      console.log('[dev] fileList', fileList);
    }
  };
  return (
    <Box sx={{ display: 'flex' }}>
      {(editMode &&
        Object.keys(files).map((fileKey) => (
          <FilePreview
            // key={`file-${file?.name}`}
            // file={file}
            key={`file-${fileKey}`}
            file={files[fileKey]}
            editMode={editMode}
            onRemove={handleRemoveFile}
          />
        ))) ||
        files?.map(({ id, file }) => (
          <FilePreview
            // key={`file-${file?.name}`}
            // file={file}
            key={`file-${id}`}
            file={file}
            editMode={editMode}
          />
        ))}
      {editMode && (
        <Grid item xs={2}>
          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              multiple
              onChange={selectFiles}
            />
            <UploadButton
              sx={{
                width: '108px',
                height: '108px',
                fontSize: '0.75rem',
                m: 0,
              }}
              startIcon={<PlusIcon />}
            >
              Add Photos
            </UploadButton>
          </label>
        </Grid>
      )}
    </Box>
  );
}
export default UIFiles;
