import { configureStore } from '@reduxjs/toolkit';
import counterSlice from '@features/counter/counterSlice';
import candidateSlice from '@features/candidates/candidateSlice';
import jobSlice from '@features/jobs/jobSlice';
import priceSlice from '@features/price/priceSlice';
import employeeSlice from '@features/employee/employeeSlice';
import utilSlice from '@features/util/utilSlice';

import {
  authInitialState,
  authSlice,
  removeToken,
  retriveToken,
} from '@features/auth/authSlice';
import { apiUserRequest } from '@features/auth/authAPI';

async function getInitialState() {
  try {
    const token = retriveToken('token');
    const id = retriveToken('id');
    if (token) {
      const { data, status } = await apiUserRequest(id)(token);
      if (status === 201 || status === 200) {
        console.log('[dev] token', token);
        return {
          status: 'idle',
          user: data,
          token,
        };
      }
      console.log('[dev] no token');
      removeToken('token');
      removeToken('id');
      return authInitialState;
    }
    return authInitialState;
  } catch (err) {
    return authInitialState;
  }
}

export const initStore = async () => {
  // get initial state
  const authIS = await getInitialState();
  const authS = authSlice(authIS);
  const store = configureStore({
    reducer: {
      counter: counterSlice,
      auth: authS.reducer,
      candidate: candidateSlice,
      job: jobSlice,
      price: priceSlice,
      employee: employeeSlice,
      util: utilSlice,
    },
  });
  return store;
};
