import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  apiJobApplyRequest,
  apiJobCategoryRequest,
  apiJobCreateRequest,
  apiJobDetailRequest,
  apiJobRemoveRequest,
  apiJobRequest,
  apiJobSavedRequest,
  apiJobSaveRequest,
  apiJobUpdateRequest
} from './jobAPI'

const initialState = {
  status: 'idle',
  categories: [],
  myJobs: [],
  savedJobs: [],
  newJob: {},
  jobDetail: {}
}

export const jobCategoryRequest = createAsyncThunk(
  'job/jobCategoryRequest',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiJobCategoryRequest(payload)(token)

      if (status === 201 || status === 200) {
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] job/jobCategoryRequest::response::catch', err)
    }
  }
)

export const jobRequest = createAsyncThunk(
  'job/jobRequest',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiJobRequest(payload)(token)

      if (status === 201 || status === 200) {
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] job/jobRequest::response::catch', err)
    }
  }
)

export const jobDetailRequest = createAsyncThunk(
  'job/jobDetailRequest',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiJobDetailRequest(payload)(token)

      if (status === 201 || status === 200) {
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] job/jobDetailRequest::response::catch', err)
    }
  }
)

export const jobApplyRequest = createAsyncThunk(
  'job/jobApplyRequest',
  async (job, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiJobApplyRequest({ job })(token)

      if (status === 201 || status === 200) {
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] job/jobApplyRequest::response::catch', err)
    }
  }
)

export const jobSavedRequest = createAsyncThunk(
  'job/jobSavedRequest',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiJobSavedRequest()(token)

      if (status === 201 || status === 200) {
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] job/jobSavedRequest::response::catch', err)
    }
  }
)

export const jobSaveRequest = createAsyncThunk(
  'job/jobSaveRequest',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiJobSaveRequest(payload)(token)

      if (status === 201 || status === 200) {
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] job/jobSaveRequest::response::catch', err)
    }
  }
)

export const jobRemoveRequest = createAsyncThunk(
  'job/jobRemoveRequest',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiJobRemoveRequest(payload)(token)

      if (200 <= status && status < 300) {
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] job/jobRemoveRequest::response::catch', err)
    }
  }
)

export const jobCreateRequest = createAsyncThunk(
  'job/jobCreateRequest',
  async ({ files, isEdit, jobId }, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { newJob } = getState().job
      const formData = new FormData()
      Object.keys(newJob).forEach(key => {
        if (key === 'locations') {
          newJob[key]?.forEach(element => {
            formData.append(key, element)
          })
        } else {
          formData.append(key, newJob[key])
        }
      })
      let i = 1
      Object.keys(files).forEach(key => {
        formData.append(`image_${i}`, files[key])
        i += 1
      })
      if (isEdit) {
        console.log('isEdit', isEdit)
        const { data, status } = await apiJobUpdateRequest(
          jobId,
          formData
        )(token)

        if (status === 201 || status === 200) {
          return data
        }
        return rejectWithValue(data)
      } else {
        console.log('isEdit', isEdit)
        const { data, status } = await apiJobCreateRequest(formData)(token)

        if (status === 201 || status === 200) {
          return data
        }
        return rejectWithValue(data)
      }
    } catch (err) {
      console.log('[dev] job/jobCreateRequest::response::catch', err)
    }
  }
)

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    clearJob: state => {
      state.newJob = {
        visible: true,
        locations: []
      }
    },
    updateJob: (state, action) => {
      // state.newJob = { ...state.newJob, ...action.payload };
      const { key, value } = action.payload
      state.newJob[key] = value
    },
    jobRemoveFromList: (state, action) => {
      // state.newJob = { ...state.newJob, ...action.payload };
      const id = action.payload
      state.myJobs = state.myJobs.filter(j => j.id !== id)
    }
  },
  extraReducers: builder => {
    builder
      .addCase(jobCategoryRequest.pending, state => {
        state.status = 'loading'
      })
      .addCase(jobCategoryRequest.fulfilled, (state, action) => {
        state.categories = action.payload
        state.status = 'idle'
      })
      .addCase(jobRemoveRequest.pending, state => {
        state.status = 'loading'
      })
      .addCase(jobRemoveRequest.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(jobSaveRequest.pending, state => {
        state.status = 'loading'
      })
      .addCase(jobSaveRequest.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(jobSavedRequest.pending, state => {
        state.status = 'loading'
      })
      .addCase(jobSavedRequest.fulfilled, (state, action) => {
        state.savedJobs = action.payload
        state.status = 'idle'
      })
      .addCase(jobRequest.pending, state => {
        state.status = 'loading'
      })
      .addCase(jobRequest.fulfilled, (state, action) => {
        state.myJobs = action.payload
        state.status = 'idle'
      })
      .addCase(jobDetailRequest.pending, state => {
        state.status = 'loading'
      })
      .addCase(jobDetailRequest.fulfilled, (state, action) => {
        state.jobDetail = action.payload
        state.status = 'idle'
      })
      .addCase(jobApplyRequest.pending, state => {
        state.status = 'loading'
      })
      .addCase(jobApplyRequest.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(jobCreateRequest.pending, state => {
        state.status = 'loading'
      })
      .addCase(jobCreateRequest.fulfilled, (state, action) => {
        state.status = 'idle'
      })
  }
})

export const { clearJob, updateJob, jobRemoveFromList } = jobSlice.actions

export const selectCategories = state => state.job.categories
export const selectNewJob = state => state.job.newJob
export const selectMyJob = state => state.job.myJobs
export const selectJobDetail = state => state.job.jobDetail
export const selectSavedJobs = state => state.job.savedJobs
export const selectIsLoadingJob = state => state.job.status === 'loading'

export default jobSlice.reducer
