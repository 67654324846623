import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { createTheme } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

// https://mui.com/customization/default-theme/

export const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#E9E9E9',
          },
        },
        // also works
        // notchedOutline: {
        //   borderColor: 'green',
        //   // '& $notchedOutline': {
        //   //   borderColor: 'green',
        //   // },
        //   // '&:hover $notchedOutline': {
        //   //   borderColor: 'red',
        //   // },
        //   // '&$focused $notchedOutline': {
        //   //   borderColor: 'purple',
        //   // },
        // },
      },
    },
  },
  typography: {
    fontFamily: [
      'pangram',
      'Cairo',
      '"Segoe UI"',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      '"Fira Sans"',
      '"Droid Sans"',

      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
  palette: {
    background: {
      paper: '#FFF',
      checked: '#FAFAFA',
      default: '#FAFAFA',
    },
    shadows: [
      '0px 10px 30px rgba(0, 0, 0, 0.05),0px 10px 30px rgba(0, 0, 0, 0.05),0px 10px 30px rgba(0, 0, 0, 0.05)',
    ],
    // b25b8dcdacc827d5d1fb91ce3dff47d946f5e253
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#1E2120',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    neutral: {
      main: '#E9E9E9',
      contrastText: '#2B2A2A',
    },
    secondary: {
      // light: '#0066ff',
      // main: '#FFA842',
      main: '#FF9141',
      contrastText: '#fff',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    // being used:
    facebook: '#3B72FF',
    orange: '#FFA842',
    gray: '#1E2120',

    // examples:
    darkGray: '#ACB9C2',
    lightGray: '#E9E9E9',
    darkBlue: '#274F66',
    lightBlue: '#0E82C8',
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});
