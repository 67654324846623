const BASE_URL = 'https://www.trylanderapp.com';

const getFetch = ({ url, payload, token, method, type }) => {
  const baseConfig = {
    method,
    headers: {
      Accept: 'application/json',
      // "Content-Type": type,
    },
    redirect: 'follow',
  };
  if (type !== 'none') {
    baseConfig.headers['Content-Type'] = type;
  }
  if (method !== 'GET' && payload) {
    if (type === 'application/json') {
      baseConfig.body = JSON.stringify(payload);
    } else {
      baseConfig.body = payload;
    }
  }
  if (token) {
    baseConfig.headers.Authorization = `Token ${token}`;
  }

  return fetch(`${BASE_URL}${url}`, baseConfig).then(async (response) => {
    let data = {};
    let error = undefined;
    try {
      data = await response.json();
      // data = response;
    } catch (err) {
      error = err;
    }
    const result = {
      [method]: url,
      status: response?.status,
      data,
      error,
      response,
    };
    return result;
  });
};

export const apiFetch = {
  post:
    (url, payload, type = 'application/json') =>
    (token) =>
      getFetch({ url, payload, token, method: 'POST', type }),
  patch:
    (url, payload, type = 'application/json') =>
    (token) =>
      getFetch({ url, payload, token, method: 'PATCH', type }),
  delete:
    (url, payload, type = 'application/json') =>
    (token) =>
      getFetch({ url, payload, token, method: 'DELETE', type }),
  get:
    (url, payload, type = 'application/json') =>
    (token) =>
      getFetch({ url, payload, token, method: 'GET', type }),
};
