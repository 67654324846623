import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { styled, darken, lighten } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { purple } from '@mui/material/colors';
import SocialLogin from 'react-social-login';

class SocialButtonFacebookClass extends React.Component {
  render() {
    const { children, triggerLogin, ...props } = this.props;
    return (
      <SocialButtonFacebookBtn onClick={triggerLogin} {...props}>
        {children}
      </SocialButtonFacebookBtn>
    );
  }
}

class SocialButtonGmailClass extends React.Component {
  render() {
    const { children, triggerLogin, ...props } = this.props;
    return (
      <SocialButtonGmailBtn onClick={triggerLogin} {...props}>
        {children}
      </SocialButtonGmailBtn>
    );
  }
}

export const SocialButtonFacebook = SocialLogin(SocialButtonFacebookClass);
export const SocialButtonGmail = SocialLogin(SocialButtonGmailClass);

const SocialButtonFacebookBtn = styled(({ children, ...props }) => (
  <Button {...props}>
    <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
      {children}
    </Box>
  </Button>
))(({ theme }) => ({
  color: theme.palette.getContrastText('#3B72FF'),
  textTransform: 'none',
  backgroundColor: '#3B72FF',
  justifyContent: 'flex-start',
  padding: 0,
  height: 62,
  borderRadius: '10px',
  border: 'none',
  fontSize: 14,
  fontWeight: '500',
  // flexDirection: 'column',
  '& .MuiButton-startIcon': {
    width: '60px',
    // padding: '0 16px',
    margin: '0',
    height: '100%',
    borderRight: `solid 1px ${darken('#3B72FF', 0.2)}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '&:hover': {
    border: 'none',
    backgroundColor: darken('#3B72FF', 0.2),
  },
}));

const gmbg = '#ffffff';
export const SocialButtonGmailBtn = styled(({ children, ...props }) => (
  <Button {...props}>
    <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
      {children}
    </Box>
  </Button>
))(({ theme }) => ({
  color: theme.palette.getContrastText(gmbg),
  textTransform: 'none',
  backgroundColor: gmbg,
  justifyContent: 'flex-start',
  padding: 0,
  height: 62,
  borderRadius: '10px',
  borderColor: darken(gmbg, 0.2),
  fontSize: 14,
  fontWeight: '500',
  '& .MuiButton-startIcon': {
    width: '60px',
    margin: '0',
    height: '100%',
    borderRight: `solid 1px ${darken(gmbg, 0.2)}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '&:hover': {
    borderColor: darken(gmbg, 0.2),
    backgroundColor: darken(gmbg, 0.2),
  },
}));

const filterColor = 'rgba(51, 51, 51, 0.5)';

export const FilterButton = styled(({ children, ...props }) => (
  <Button {...props}>
    <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
      {children}
    </Box>
  </Button>
))(({ theme }) => ({
  color: filterColor,
  textTransform: 'none',
  backgroundColor: 'transparent',
  // backgroundColor: 'pink',
  justifyContent: 'flex-start',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  height: 40,
  borderRadius: '8px',
  // borderColor: filterColor,
  borderColor: '#C4C4C4',
  borderWidth: '1px',
  borderStyle: 'solid',

  fontSize: '1rem',
  fontWeight: '400',
  '& .MuiButton-startIcon': {
    paddingRight: theme.spacing(1),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const UploadButton = ({ children, sx, ...props }) => {
  return (
    <UIButton
      className="btn-choose"
      color="primary"
      variant="outlined"
      component="span"
      sx={{
        width: '177px',
        height: '174px',
        py: '16px',
        color: '#000',
        fontSize: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        my: 3,
        '& .MuiButton-startIcon': {
          margin: 0,
          py: 1,
        },
        borderStyle: 'dashed',
        background: 'rgba(0, 0, 0, 0.06)',
        ':hover': {
          borderStyle: 'dashed',
          background: 'rgba(0, 0, 0, 0.09)',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </UIButton>
  );
};

export const ChipButton = styled(({ children, ...props }) => (
  <Button {...props}>
    <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
      {children}
    </Box>
  </Button>
))(({ theme }) => ({
  color: filterColor,
  textTransform: 'none',
  backgroundColor: 'transparent',
  // backgroundColor: 'pink',
  justifyContent: 'flex-start',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  height: 40,
  borderRadius: '8px',
  // borderColor: filterColor,
  borderColor: '#C4C4C4',
  borderWidth: '1px',
  borderStyle: 'solid',

  fontSize: '1rem',
  fontWeight: '400',
  '& .MuiButton-startIcon': {
    paddingRight: theme.spacing(1),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const UIButton = ({ children, sx = {}, ...props }) => {
  return (
    <Button
      color="secondary"
      variant="contained"
      sx={{
        my: 1,
        py: '21px',
        // color: 'white',
        // display: 'block',
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: '500',
        borderRadius: '8px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
export default UIButton;
