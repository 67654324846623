import { apiFetch } from '@utils/apiFetch'

const authAPI = apiFetch

export function apiGetPlans () {
  return authAPI.get(`/api/v1/subscriptions/plans/`)
}

export function apiGetPaymentMethod () {
  return authAPI.get(`/api/v1/subscriptions/my_cards/`)
}

export function addPayMethod (payload) {
  return authAPI.post(`/api/v1/subscriptions/add_payment_method/`, payload)
}

export function deletePayMethod (payload) {
  return authAPI.post(`/api/v1/subscriptions/revoke_payment_method/`, payload)
}

export function subscribePlan (payload) {
  return authAPI.post(`/api/v1/subscriptions/subscribe/`, payload)
}

export function changePlan (payload) {
  return authAPI.patch(`/api/v1/subscriptions/change_subscription/`, payload)
}

export function unsubscribePlan () {
  return authAPI.get(`/api/v1/subscriptions/unsubscribe/`)
}
