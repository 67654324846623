import * as React from 'react';
import { useIMask } from 'react-imask';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

const UIMenu = ({ listItem = [], el: { anchorEl, setAnchorEl }, ...props }) => {
  const handleCloseNavMenu = (action) => {
    action();
    handleCloseUserMenu();
  };
  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };
  return (
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleCloseUserMenu}
      MenuListProps={{
        // disableListWrap: false,
        sx: {
          display: 'flex',
          flexDirection: 'column',
          // borderRadius: '8px',
          py: 0,
          '& li': {
            fontWeight: 200,
            py: '12px',
          },
        },
      }}
    >
      {listItem?.map(
        ({ label, action, leftIcon, rightIcon, menuItem }) =>
          menuItem || (
            <MenuItem key={label} onClick={() => handleCloseNavMenu(action)}>
              {leftIcon && (
                <Avatar
                  sx={{
                    mr: 2,
                    bgcolor: 'rgba(196, 196, 196, 0.14)',
                    width: 48,
                    height: 48,
                  }}
                >
                  {leftIcon}
                </Avatar>
              )}
              <Typography textAlign="center">{label}</Typography>
              {rightIcon && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    flexGrow: 1,
                    ml: 1,
                  }}
                >
                  {rightIcon}
                </Box>
              )}
            </MenuItem>
          )
      )}
    </Menu>
  );
};

export default UIMenu;
