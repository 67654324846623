import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation, useMatch, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '@features/auth/authSlice';
import WrapperContent from '@components/WrapperContent';

// import { ReactComponent as CheckmarkIcon } from '@assets/icons/checkmark.svg';

import { CreateJobButton } from './PageEmptyJob';
import PageJobCreate from './PageJobCreate';
import {
  jobRequest,
  jobSavedRequest,
  selectMyJob,
  selectSavedJobs,
} from '@features/jobs/jobSlice';
import PageJobList from './PageJobList';

const PageJobsCandidate = ({ saved = false }) => {
  const dispatch = useDispatch();
  const myJobs = useSelector(selectMyJob);
  const savedJobs = useSelector(selectSavedJobs);
  const location = useLocation();
  React.useEffect(() => {
    if (saved) {
      dispatch(jobSavedRequest());
    } else {
      dispatch(jobRequest());
    }
  }, [dispatch, location, saved]);

  return (
    <WrapperContent
      label={saved ? 'Saved Job Post' : 'Jobs'}
      // actions={section === '' && myJobs?.length && <CreateJobButton />}
    >
      <PageJobList myJobs={saved ? savedJobs : myJobs} />
    </WrapperContent>
  );
};
export default PageJobsCandidate;
