import * as React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { selectNewJob, updateJob } from '@features/jobs/jobSlice'

import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import UITextField from '@components/UITextField'
import UIFiles from '@components/UIFiles'
import { ReactComponent as LocationIcon } from '@assets/icons/location.svg'
import { ButtonJobContinue } from './PageJobCreate'
import { TextSubTitle } from './components'
import { updateSnack } from '@features/util/utilSlice'
import { STATE_TYPES } from '@utils/phoneMask'
import { MenuItem } from '@mui/material'

const JobSectionDescription = ({ onSubmit, files, setFiles, jobDetail }) => {
  const { description, estimated_time, locations } = useSelector(selectNewJob)
  const dispatch = useDispatch()

  const [inputValue, setInputValue] = React.useState('')

  React.useEffect(() => {
    if (jobDetail) {
      handleChange('description')(jobDetail?.description)
      handleChange('estimated_time')(jobDetail?.estimated_time)
      handleChange('locations')(jobDetail?.locations)
    }
  }, [jobDetail])

  const handleChange = key => value => {
    dispatch(updateJob({ key, value }))
    dispatch(
      updateSnack({
        open: false
      })
    )
  }
  const handleChangeValue = key => event => {
    handleChange(key)(event.target.value)
  }
  const handleSubmit = () => {
    if (!description) {
      dispatch(
        updateSnack({
          open: true,
          severity: 'error',
          message: 'Please enter a description'
        })
      )
    } else if (!estimated_time) {
      dispatch(
        updateSnack({
          open: true,
          severity: 'error',
          message: 'Please select estimated time'
        })
      )
    } else if (!locations?.length) {
      dispatch(
        updateSnack({
          open: true,
          severity: 'error',
          message: 'Please enter a location'
        })
      )
    } else {
      onSubmit()
    }
  }

  const getLocations = location => {
    log
    if (location) {
      return location[0]?.split(',')
    }
  }

  console.log('locations', locations)
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextSubTitle>Job Description</TextSubTitle>
        <UITextField
          id='description'
          value={description}
          placeholder='Write a job description'
          // icon={<MessageIcon />}
          onChange={handleChange('description')}
          multiline
          // minRows={5}
          // error={errors?.email}
        />
        <TextSubTitle>Employment</TextSubTitle>
        <RadioGroup
          id='estimated_time'
          row
          aria-label='estimated time'
          name='salary-type-group'
          value={estimated_time || ''}
          onChange={handleChangeValue('estimated_time')}
        >
          <FormControlLabel
            value='Full-Time'
            control={<Radio />}
            label='Full-Time'
          />
          <FormControlLabel
            value='Part-Time'
            control={<Radio />}
            label='Part-Time'
          />
          <FormControlLabel
            value='Contract'
            control={<Radio />}
            label='Contract'
          />
          <FormControlLabel
            value='Temp to hire'
            control={<Radio />}
            label='Temp to hire'
          />
        </RadioGroup>
        <TextSubTitle>Locations</TextSubTitle>
        {/* <Autocomplete
          multiple
          freeSolo
          id='prefered-locations'
          options={[]}
          value={locations?.length ? locations : []}
          inputValue={inputValue}
          onChange={(event, newValue) => {
            handleChange('locations')(newValue)
          }}
          onInputChange={(event, newInputValue) => {
            console.log('newInputValue', newInputValue)
            const options = newInputValue.split(',')
            console.warn('options', options)
            if (options?.length > 1) {
              handleChange('locations')(
                locations
                  .concat(options)
                  .map(x => x.trim())
                  .filter(x => x)
              )
              setInputValue('')
            } else {
              setInputValue(newInputValue)
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              // label="Prefered Locations"
              placeholder='Use , as a delimiter'
            />
          )}
        /> */}
        <UITextField
          id='state'
          label='State'
          multiple
          placeholder='Enter your state'
          icon={<LocationIcon />}
          sx={{ gridColumn: 'span 2' }}
          onChange={value =>
            handleChange('locations')(
              typeof value === 'string' ? value.split(',') : value
            )
          }
          value={locations?.length ? locations : []}
          // error={errors?.state}
          select
        >
          <MenuItem value=''>
            <em>Select your state</em>
          </MenuItem>
          {STATE_TYPES.map((state, index) => (
            <MenuItem key={index} value={state}>
              {state}
            </MenuItem>
          ))}
        </UITextField>
        <TextSubTitle>Add Files</TextSubTitle>
        <UIFiles files={files} setFiles={setFiles} editMode />
        <TextSubTitle
          sx={{
            mt: 0,
            fontSize: '0.875rem' // 14px
          }}
        >
          You can upload max. 5 images
        </TextSubTitle>
      </Box>
      <ButtonJobContinue onSubmit={handleSubmit} />
    </>
  )
}

export default JobSectionDescription
