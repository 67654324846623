import * as React from 'react'
import UIButton from '@components/UIButton'
import {
  Grid,
  Typography,
  Paper,
  Divider,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  CircularProgress,
  IconButton,
  Dialog,
  Box
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import WrapperContent from '@components/WrapperContent'
import {
  logoutRequest,
  selectUser,
  selectUserProfile,
  updateProfileRequest,
  userDeleteProfileRequest,
  userProfileRequest
} from '@features/auth/authSlice'
import { updateSnack } from '@features/util/utilSlice'
import UITextField from '@components/UITextField'
import UISwitch from '@components/UISwitch'
import { useEffect, useState } from 'react'
import { jobCategoryRequest } from '@features/jobs/jobSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg'

const PageSettings = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(selectUser)
  const userProfile = useSelector(selectUserProfile)
  const { profileId = '' } = useParams()
  useEffect(() => {
    if (profileId) {
      dispatch(userProfileRequest(profileId))
    } else if (user?.id) {
      dispatch(userProfileRequest(user?.id))
    }
    dispatch(jobCategoryRequest())
  }, [dispatch, profileId, user])
  const userView = profileId ? userProfile : user

  const [state, setState] = useState({
    first_name: userView?.first_name || '',
    last_name: userView?.last_name || '',
    email: userView?.email || '',
    oldpassword: '',
    password: '',
    job_post_notifications: userView?.job_post_notifications || false,
    interview_notifications: userView?.interview_notifications || false,
    message_notifications: userView?.message_notifications || false,
    allow_chat: userView?.allow_chat || false,
    loading: false,
    loadingDelete: false,
    open: false
  })

  const onChange = key => value => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleChangeChecked = key => event => {
    onChange(key)(event.target.checked)
  }

  console.log('userView', userView?.id)
  const {
    email,
    oldpassword,
    password,
    job_post_notifications,
    allow_chat,
    interview_notifications,
    message_notifications,
    loading,
    loadingDelete,
    open
  } = state

  const handleSaveProfile = () => {
    onChange('loading')(true)
    const formData = new FormData()

    formData.append(`interview_notifications`, interview_notifications)
    formData.append(`message_notifications`, message_notifications)
    formData.append(`job_post_notifications`, job_post_notifications)
    formData.append(`allow_chat`, allow_chat)
    password && formData.append(`password`, password)
    dispatch(updateProfileRequest(formData))
      .then(unwrapResult)
      .then(data => {
        onChange('loading')(false)
        dispatch(
          updateSnack({
            open: true,
            severity: 'success',
            message: 'Settings Saved'
          })
        )
        console.log('[dev]PageLocation::data', data)
      })
      .catch(data => {
        onChange('loading')(false)
        console.log('[dev]PageLocation::catch::', data)
      })
  }

  const handleDeleteProfile = () => {
    onChange('loadingDelete')(true)
    dispatch(userDeleteProfileRequest())
      .then(unwrapResult)
      .then(data => {
        navigate('/account-deleted')
        dispatch(logoutRequest()).then(() => {
          onChange('loadingDelete')(false)
        })
      })
      .catch(data => {
        onChange('loadingDelete')(false)
        console.log('[dev]PageLocation::catch::', data)
      })
  }

  const handleClose = () => {
    onChange('open')(false)
  }

  return (
    <WrapperContent
      label='Account Settings'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Paper elevation={0} sx={{ width: '100%', mb: 5, p: 5, borderRadius: 5 }}>
        <Typography variant='h5'>Basic Info</Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid item md={6} xs={12} container spacing={3}>
          <Grid item xs={12}>
            <UITextField
              label={'Enter email address'}
              value={email}
              disabled={email}
              // onChange={onChange('last_name')}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <UITextField
              label={'Old Password'}
              value={oldpassword}
              onChange={onChange('oldpassword')}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <UITextField
              label={'New Password'}
              value={password}
              onChange={onChange('password')}
            />
          </Grid>
        </Grid>
      </Paper>
      {/* {userView?.type !== 'Company' && ( */}
      <Paper elevation={0} sx={{ width: '100%', mb: 5, p: 5, borderRadius: 5 }}>
        <Typography variant='h5'>Notifications</Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid
          item
          md={4}
          xs={8}
          container
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography>Message</Typography>
          <FormControlLabel
            id='message_notifications'
            // control={<UISwitch defaultChecked />}
            control={<UISwitch />}
            sx={{ ml: 0, mt: 1 }}
            label=''
            // labelPlacement="start"
            checked={message_notifications}
            onChange={handleChangeChecked('message_notifications')}
          />
        </Grid>
        {/* <Grid
          item
          md={4}
          xs={8}
          container
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography>Job Post</Typography>
          <FormControlLabel
            id='job_post_notifications'
            // control={<UISwitch defaultChecked />}
            control={<UISwitch />}
            sx={{ ml: 0, mt: 1 }}
            label=''
            // labelPlacement="start"
            checked={job_post_notifications}
            onChange={handleChangeChecked('job_post_notifications')}
          />
        </Grid>
        <Grid
          item
          md={4}
          xs={8}
          container
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography>Ask for interview</Typography>
          <FormControlLabel
            id='interview_notifications'
            // control={<UISwitch defaultChecked />}
            control={<UISwitch />}
            sx={{ ml: 0, mt: 1 }}
            label=''
            // labelPlacement="start"
            checked={interview_notifications}
            onChange={handleChangeChecked('interview_notifications')}
          />
        </Grid> */}
      </Paper>
      {/* )} */}
      {/* <Paper elevation={0} sx={{ width: '100%', mb: 5, p: 5, borderRadius: 5 }}>
        <Typography variant='h5'>Message</Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <FormControl>
          <RadioGroup
            aria-labelledby='demo-radio-buttons-group-label'
            defaultValue='female'
            name='radio-buttons-group'
          > */}
            {/* <FormControlLabel
              value='on'
              control={<Radio />}
              label={
                userView?.type === 'Company'
                  ? 'Turn on chat for all hiring managers'
                  : 'Turn on chat for all candidates'
              }
            />
            <FormControlLabel
              value='specific'
              control={<Radio />}
              label={
                userView?.type === 'Company'
                  ? 'Turn on chat for Specific hiring manager'
                  : 'Turn on chat for Specific candidates'
              }
            /> */}
            {/* <FormControlLabel
              value='off'
              control={<Radio />}
              /> */}
            {/* <FormControlLabel
              label='Turn off chat'
              id='interview_notifications'
              // control={<UISwitch defaultChecked />}
              control={<UISwitch />}
              sx={{ ml: 0, mt: 1 }}
              // labelPlacement="start"
              checked={allow_chat}
              onChange={handleChangeChecked('allow_chat')}
            />
          </RadioGroup>
        </FormControl>
      </Paper> */}
      <Grid container sx={{ mb: 3 }}>
        <UIButton
          onClick={() => onChange('open')(true)}
          sx={{
            width: '150px',
            height: 50,
            mr: 2,
            backgroundColor: 'rgba(253, 64, 58, 1)'
          }}
        >
          {loadingDelete ? (
            <CircularProgress style={{ color: '#fff' }} />
          ) : (
            'Delete Account'
          )}
        </UIButton>
        <UIButton
          onClick={handleSaveProfile}
          sx={{
            width: '150px',
            height: 50,
            my: 1,
            backgroundColor: 'rgba(30, 33, 32, 1)'
          }}
        >
          {loading ? (
            <CircularProgress style={{ color: '#fff' }} />
          ) : (
            'Save Changes'
          )}
        </UIButton>
      </Grid>
      <Dialog onClose={handleClose} open={open} sx={{ borderRadius: '10px' }}>
        <Box sx={{ position: 'relative', m: 5 }}>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: 24, // 21
              textAlign: 'center',
              mx: 5,
              mb: 2
            }}
          >
            CAUTION
          </Typography>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '1.1.3125rem', // 21
              textAlign: 'center',
              mx: 5
            }}
          >
            You're about to delete your account. Please note once your account
            is deleted, it can't be restored as your personal data will be
            deleted and access will be ceased. You would need to create a new
            Lander profile to gain access again to our services.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', mx: 4, mb: 2 }}>
          <DialogButton color='neutral' onClick={handleClose}>
            No
          </DialogButton>
          <DialogButton color='primary' onClick={handleDeleteProfile}>
            Yes
          </DialogButton>
        </Box>
        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <IconButton
            onClick={handleClose}
            aria-label='save'
            sx={{
              m: 2,
              width: '46px',
              height: '46px',
              // boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
              backgroundColor: 'rgba(196, 196, 196, 0.3)'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Dialog>
    </WrapperContent>
  )
}

function DialogButton ({ ...props }) {
  return (
    <UIButton
      sx={{
        //
        py: 1,
        flexGrow: 1,
        mx: 1
      }}
      {...props}
    />
  )
}

export default PageSettings
