import * as React from 'react'
import moment from 'moment'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Grow from '@mui/material/Grow'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'

import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg'
import { ReactComponent as ShowIcon } from '@assets/icons/show.svg'
import { ReactComponent as LocationIcon } from '@assets/icons/location.svg'

import { ReactComponent as BookmarkIcon } from '@assets/icons/bookmark.svg'
import { ReactComponent as Bookmark2Icon } from '@assets/icons/bookmarkOutlined.svg'
import { ReactComponent as MenuIcon } from '@assets/icons/menu.svg'
import { ReactComponent as TimeIcon } from '@assets/icons/time.svg'
import { ReactComponent as PriceIcon } from '@assets/icons/price.svg'

import { ReactComponent as EditIcon } from '@assets/icons/edit.svg'
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg'
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg'

import UICard from '@components/UICard'
import UIButton, { ChipButton } from '@components/UIButton'
import { useMenu } from '@utils/common'
import UIMenu from '@components/UIMenu'
import { useDispatch, useSelector } from 'react-redux'
import { jobRemoveFromList, jobRemoveRequest } from '@features/jobs/jobSlice'
import { CategoryChip } from './components'
import { unwrapResult } from '@reduxjs/toolkit'
import { updateSnack } from '@features/util/utilSlice'
import { NavLink } from 'react-router-dom'
import { selectCurrentUser } from '@features/auth/authSlice'

// {
//   id: 2,
//   company: {
//     id: 13,
//     first_name: 'Mark',
//     last_name: 'Zuckerberg',
//     email: 'comp3@crowdbotics.com',
//     type: 'Company',
//     registration_id: null,
//     company: {
//       business_name: 'Meta',
//       contact_number: '2893829329',
//       business_type: 'Marketing',
//       position: 'CEO',
//       payment_method: '',
//       photo: 'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/profile/photos/mark-zuck-img_WQvsglJ.jpeg',
//       city: 'San Francisco',
//       zip_code: '92382',
//       state: 'California'
//     },
//     job_post_notifications: true,
//     interview_notifications: true,
//     message_notifications: true
//   },
//   looking_for: 'Business',
//   role: 'Customer Service',
//   files: [
//     {
//       id: 6,
//       file: 'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/jobs/files/pexels-photo-771742_PYz3S8F.jpeg'
//     },
//     {
//       id: 7,
//       file: 'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/jobs/files/pexels-photo-937481.jpeg'
//     },
//     {
//       id: 8,
//       file: 'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/jobs/files/pexels-pixabay-220453.jpeg'
//     }
//   ],
//   posted: 'Posted 0 day(s) ago',
//   applied_candidates: [],
//   number_of_candidates: 0,
//   applied: null,
//   saved: null,
//   name: 'Admin Job',
//   description: 'Manager custom services',
//   state: null,
//   zip_code: null,
//   city: null,
//   salary_type: 'One month',
//   budget: 300,
//   negotiated: true,
//   estimated_time: '3 weeks',
//   visible: true,
//   created_at: '2022-01-13',
//   views: 0
// }
const UIAvatar = ({ children, sx, ...props }) => {
  return (
    <Avatar
      sx={{
        ///
        bgcolor: '#F1F1F1',
        ...sx
      }}
      {...props}
    >
      {children}
    </Avatar>
  )
}

export const JobActionIcon = ({ children, sx, ...props }) => {
  return (
    <IconButton
      aria-label='save'
      sx={{
        ml: 2,
        width: '42px',
        height: '42px',
        boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
        ...sx
      }}
      {...props}
    >
      {children}
    </IconButton>
  )
}

const IconDetail = ({ Icon, label }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flex: '1 1 0'
      }}
    >
      <UIAvatar
        sx={{
          width: '46px',
          height: '46px'
        }}
      >
        <Icon fill='#333333' height={20} width={20} />
      </UIAvatar>
      <Typography
        sx={{
          fontWeight: '500',
          fontSize: '0.875rem', // 14px
          lineHeight: '24.78px',
          ml: 2
        }}
      >
        {label}
      </Typography>
    </Box>
  )
}

const DetailItem = ({ Icon, label }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        my: 1
      }}
    >
      <Icon fill='#333333' height={20} width={20} />
      <Typography
        sx={{
          fontWeight: '400',
          fontSize: '1rem', // 14px
          lineHeight: '19.2px',
          ml: 1
        }}
      >
        {label}
      </Typography>
    </Box>
  )
}

function DialogButton ({ ...props }) {
  return (
    <UIButton
      sx={{
        //
        py: 1,
        flexGrow: 1,
        mx: 1
      }}
      {...props}
    />
  )
}

function UIDialog (props) {
  const { onClose, onConfirm, value, open } = props

  const handleClose = () => {
    onClose(value)
  }

  const handleConfirm = () => {
    onConfirm(value)
  }

  return (
    <Dialog onClose={handleClose} open={open} sx={{ borderRadius: '10px' }}>
      <Box sx={{ position: 'relative', m: 5 }}>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '1.1.3125rem', // 21
            textAlign: 'center',
            mx: 5
          }}
        >
          Are you sure want to delete
          <br />
          this job post?
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mx: 4, mb: 2 }}>
        <DialogButton color='neutral' onClick={handleClose}>
          Cancel
        </DialogButton>
        <DialogButton color='primary' onClick={handleConfirm}>
          Delete
        </DialogButton>
      </Box>
      <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
        <IconButton
          onClick={handleClose}
          aria-label='save'
          sx={{
            m: 2,
            width: '46px',
            height: '46px',
            // boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
            backgroundColor: 'rgba(196, 196, 196, 0.3)'
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Dialog>
  )
}

const JobCard = ({
  id,
  name,
  description,
  looking_for,
  role,
  saved,
  views,
  created_at,
  city,
  negotiated,
  estimated_time,
  isCompany,
  navigate,
  locations,
  onSave = () => {}
}) => {
  const [isSaved, setIsSaved] = React.useState(saved)
  const [show, setShow] = React.useState(true)
  const [el, handleOpenMenu] = useMenu()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  const handleDialogClose = () => {
    setDialogOpen(false)
  }
  const handleDialogConfirm = () => {
    setDialogOpen(false)
    dispatch(jobRemoveRequest(id))
      .then(unwrapResult)
      .then(() => {
        setShow(false)
        dispatch(jobRemoveFromList(id))
        dispatch(
          updateSnack({
            open: true,
            severity: 'success',
            message: 'Job Removed'
          })
        )
      })
      .catch(err => {
        console.log('[dev] errrrrr', err)
        dispatch(
          updateSnack({
            open: true,
            severity: 'error',
            message: 'Error removing the job post, please refresh the page'
          })
        )
      })
    //
  }
  const settings = id => [
    {
      label: 'Edit Job post',
      action: () => {
        navigate(`/app/jobs/edit/${id}`, { state: { isEdit: true } })
      },
      leftIcon: <EditIcon />
    },
    {
      label: 'Delete Job post',
      action: () => {
        setDialogOpen(true)
      },
      leftIcon: <DeleteIcon />
    }
  ]
  return (
    <Grow in={show}>
      <UICard
        sx={{
          // width: '100%',
          // display: 'flex',
          // flexDirection: 'column',
          // flexGrow: 1,
          // backgroundColor: 'pink',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
          // p: 2,
          mb: 3
        }}
      >
        <Grid container>
          <Grid
            item
            xs={9}
            sx={{
              borderRight: '1px solid rgba(0, 0, 0, 0.1)',
              p: 2
            }}
          >
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '1.25rem', // 20px
                lineHeight: '31.6px'
              }}
            >
              <NavLink
                to={`/app/jobs/detail/${id}`}
                style={({ isActive }) => ({
                  color: '#000000',
                  textDecoration: 'none'
                })}
              >
                {name}
              </NavLink>
            </Typography>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '0.875rem', // 14px
                lineHeight: '24.78px',
                color: '#333333',
                opacity: 0.6
              }}
            >
              {description}
            </Typography>
            <Box sx={{ display: 'flex', my: 2 }}>
              <IconDetail
                Icon={CalendarIcon}
                label={`Posted ${moment.utc(created_at).fromNow()}`}
              />
              <IconDetail
                Icon={ShowIcon}
                label={`${views} View${views > 1 ? 's' : ''}`}
              />
              {/* <IconDetail Icon={LocationIcon} label="10001, New York" /> */}
              <IconDetail Icon={LocationIcon} label={locations.join(', ')} />
            </Box>
            <Grid container spacing={2}>
              {looking_for && <CategoryChip>{looking_for}</CategoryChip>}
              {/* {role && <CategoryChip>{role}</CategoryChip>} */}
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              //
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                //
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              {!isCompany && (
                <JobActionIcon
                  onClick={() => {
                    onSave(id, !isSaved)
                    setIsSaved(!isSaved)
                  }}
                >
                  {isSaved ? (
                    <BookmarkIcon fill='black' height={20} width={20} />
                  ) : (
                    <Bookmark2Icon height={20} width={20} />
                  )}
                </JobActionIcon>
              )}
              {isCompany && (
                <>
                  <JobActionIcon
                    disabled={
                      user?.subscribed == 'canceled' || !user?.subscription
                    }
                    onClick={handleOpenMenu}
                  >
                    <MenuIcon height={18} width={18} />
                  </JobActionIcon>
                  <UIMenu el={el} listItem={settings(id)} />
                </>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignSelf: 'center'
              }}
            >
              <Box>
                <DetailItem
                  Icon={PriceIcon}
                  label={`${negotiated ? 'Negotiable' : 'Non Negotiable'}`}
                />
                <DetailItem Icon={TimeIcon} label={`${estimated_time}`} />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: isCompany ? 'flex-start' : 'center'
              }}
            >
              <UIButton
                color='primary'
                sx={{
                  //
                  flexGrow: 1,
                  py: 1
                }}
                disabled={
                  isCompany
                    ? user?.subscribed == 'canceled' || !user?.subscription
                    : false
                }
                onClick={() => {
                  navigate(`/app/jobs/detail/${id}`, {
                    state: { isEdit: false }
                  })
                }}
              >
                {isCompany ? 'Job Detail' : 'Apply'}
              </UIButton>
            </Box>
          </Grid>
        </Grid>
        <UIDialog
          onClose={handleDialogClose}
          onConfirm={handleDialogConfirm}
          open={dialogOpen}
        />
      </UICard>
    </Grow>
  )
}

export default JobCard
