import * as React from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { currentProfileRequest, selectUser } from '@features/auth/authSlice';
import WrapperContent from '@components/WrapperContent';

// import { ReactComponent as CheckmarkIcon } from '@assets/icons/checkmark.svg';

import { CreateJobButton } from './PageEmptyJob';
import PageJobCreate from './PageJobCreate';
import { jobRequest, selectMyJob } from '@features/jobs/jobSlice';
import PageJobList from './PageJobList';
import PageJobDetail from './PageJobDetail';

const sectionMap = {
  create: { label: 'Job Post Creation', component: PageJobCreate },
  detail: { label: 'Job Details', component: PageJobDetail },
  edit: { label: 'Job Post Update', component: PageJobCreate },
};
const PageJobs = () => {
  const dispatch = useDispatch();
  const { section = '', jobId = '' } = useParams();
  const user = useSelector(selectUser);
  const myJobs = useSelector(selectMyJob);
  React.useEffect(() => {
    dispatch(currentProfileRequest(user?.id))
    if (user?.type === 'Candidate') {
      dispatch(jobRequest());
    } else {
      dispatch(jobRequest(false));
    }
    console.log('[dev] PageJobs::section::', section, jobId);
  }, [dispatch, section, jobId, user]);

  const SectionComponent = sectionMap[section]?.component;
  return (
    <WrapperContent
      label={section ? sectionMap[section]?.label : 'My Job Posts'}
      actions={section === '' && myJobs?.length > 0 && <CreateJobButton />}
    >
      {(section === '' && <PageJobList myJobs={myJobs} />) ||
        (SectionComponent && React.createElement(SectionComponent, { jobId }))}
    </WrapperContent>
  );
};
export default PageJobs;
