import * as React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { ReactComponent as EmptyIcon } from '@assets/icons/emptyJobCandidate.svg'
import UICard from '@components/UICard'

const PageEmptyJobCandidate = () => {
  return (
    <UICard
      sx={{
        //
        flexGrow: 1,
        // background: 'pink',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 3
        }}
      >
        <EmptyIcon />
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '1.75rem',
            textAlign: 'center',
            my: 3
          }}
        >
          You have not added the job posts
          <br />
          to your Saved yet
        </Typography>
      </Box>
    </UICard>
  )
}

export default PageEmptyJobCandidate
