import React, { useState, useEffect, useCallback, useRef } from 'react'

// ROUTER
import { useNavigate } from 'react-router-dom'
import { json, useParams } from 'react-router'

// REDUX
import { useDispatch, useSelector } from 'react-redux'

// MUI
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'

// INTERNAL
import {
  conversationsRequest,
  currentProfileRequest,
  readMessageRequest,
  selectConversations,
  selectCurrentUser,
  selectUser,
  selectUserProfile,
  sendMessageRequest,
  startConversationsRequest,
  updateConversationsRequest,
  userProfileRequest,
  userSearchByIdsRequest
} from '@features/auth/authSlice'
import WrapperContent from '@components/WrapperContent'
// import { ReactComponent as CheckmarkIcon } from '@assets/icons/checkmark.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg'
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg'
import { ReactComponent as SendMsgIcon } from '@assets/icons/sendMsg.svg'

// import './moderated-chat.scss';

// emoji
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'

// PUBNUB
import { usePubNub } from 'pubnub-react'

import UIButton from '@components/UIButton'
import { Avatar, ButtonBase, IconButton, Typography } from '@mui/material'
import UITextField from '@components/UITextField'
import { formatPhoneNumber } from '@utils/common'
import { database } from '@utils/firebase'

// sec-c-ZDgyNjY3YjItNDdiZS00ODcwLTliNTctNTQzMTdjOTRmNjk4

const leftSideSize = '250px'

const PageChat = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const divRef = useRef(null)
  const { msgId = '0', activeChatId = '0' } = useParams()
  const userProfile = useSelector(selectUserProfile)
  const user = useSelector(selectUser)
  const currentUser = useSelector(selectCurrentUser)
  const conversations = useSelector(selectConversations)
  const [currentChatId, setCurrentChatId] = useState(activeChatId)
  const [chatUserId, setChatUserId] = useState(+msgId)
  const [chatId, setChatId] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [showDetail, setShowDetail] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [state, setState] = useState({
    loading: false,
    List: [],
    allList: [],
    unread: [],
    searchText: '',
    isSearch: false,
    active: 0,
    messages: [],
    messageData: null
  })

  const {
    loading,
    isSearch,
    searchText,
    messageData,
    messages,
    List,
    allList
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const checkDeleted = async id => {
    const res = await dispatch(userProfileRequest(id))
    if (res?.payload?.detail === 'Not found.') {
      setIsDelete(true)
    } else {
      setIsDelete(false)
    }
  }

  useEffect(() => {
    getMessages()
  }, [])

  useEffect(() => {
    if (chatUserId) {
      createMessageList()
      dispatch(currentProfileRequest(user?.id))
    }
  }, [chatUserId])

  useEffect(() => {
    if (currentChatId) {
      getMessageList()
    }
    return () => database.ref('Messages/' + currentChatId).off('value')
  }, [currentChatId])

  const snapshotToArray = snapshot =>
    Object.entries(snapshot).map(e => Object.assign(e[1], { uid: e[0] }))

  const getMessages = async () => {
    try {
      database.ref(`Messages`).on('value', snapshot => {
        if (snapshot.val()) {
          const messages = snapshotToArray(snapshot.val())
          const filtered = messages?.filter(
            e => e?.receiverId === user?.id || e?.senderId === user?.id
          )
          handleChange('allList', filtered)
          unreadList(filtered)
          handleChange('List', filtered)
        } else {
          handleChange('allList', [])
          handleChange('List', [])
        }
      })
    } catch (error) {
      console.log('err', error)
    }
  }
  const getMessageList = async () => {
    try {
      if (currentChatId) {
        database.ref('Messages/' + currentChatId).on('value', snapshot => {
          if (snapshot.val()) {
            if (
              snapshot.val()?.senderId === user?.id &&
              snapshot.val()?.id === currentChatId
            ) {
              database
                .ref('Messages/' + currentChatId)
                .update({ senderRead: 0 })
                .then(res => {
                  database
                    .ref('Messages/' + currentChatId)
                    .once('value', snapshot => {
                      if (snapshot.val()) {
                        // getMessages()
                        setState(prevState => ({
                          ...prevState,
                          messages: snapshot.val().messages || [],
                          messageData: snapshot.val()
                        }))
                        divRef.current?.scrollIntoView({
                          behavior: 'smooth'
                        })
                      }
                    })
                })
            }
            if (
              snapshot.val()?.receiverId === user?.id &&
              snapshot.val()?.id === currentChatId
            ) {
              database
                .ref('Messages/' + currentChatId)
                .update({ receiverRead: 0 })
                .then(res => {
                  database
                    .ref('Messages/' + currentChatId)
                    .once('value', snapshot => {
                      if (snapshot.val()) {
                        // getMessages()
                        setState(prevState => ({
                          ...prevState,
                          messages: snapshot.val().messages || [],
                          messageData: snapshot.val()
                        }))
                        divRef.current?.scrollIntoView({
                          behavior: 'smooth'
                        })
                      }
                    })
                })
            }
          }
        })
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const createMessageList = async item => {
    const { payload } = await dispatch(userProfileRequest(chatUserId))
    const rid = `${chatUserId}_${user?.id}`
    const id = user?.type === 'Candidate' ? `${user?.id}_${chatUserId}` : `${chatUserId}_${user?.id}`
    const db = database
    if (user?.id !== chatUserId) {
      let value = {
        sender: user,
        senderId: user?.id,
        senderRead: 0,
        id: id,
        timeStamp: Date.now(),
        receiverRead: 0,
        receiverId: payload?.id,
        receiver: payload,
      };
      await database.ref('Messages/' + id).once('value')
      .then(snapshot => {
        if (!snapshot.exists()) {
          // Create the message thread if it doesn't exist
          database
            .ref('Messages/' + id)
            .update(value)
            .then(res => {
              console.log('Message thread created successfully');
            })
            .catch(err => {
              alert('Something went wrong!');
            });
        } else {
          console.log('Message thread already exists');
        }
      })
      .catch(err => {
        alert('Failed to check message existence');
      });
      navigate(
        `/app/chat/${
          chatUserId
        }/${id}`
      )
      // readMessages()
      setCurrentChatId(id)
    }
  }

  const unreadList = messages => {
    const unread = messages?.filter(
      item =>
        (item?.receiverId === user?.id && item?.receiverRead > 0) ||
        (item?.senderId === user?.id && item?.senderRead > 0)
    )
    handleChange('unread', unread)
  }

  const sortByDate = data => {
    return data?.sort(function (a, b) {
      return (
        new Date(
          b?.messages && b?.messages?.length > 0
            ? b?.messages[b?.messages?.length - 1]?.timeStamp
            : b?.timeStamp
        ) -
        new Date(
          a?.messages && a?.messages?.length > 0
            ? a?.messages[a?.messages?.length - 1]?.timeStamp
            : a?.timeStamp
        )
      )
    })
  }
  const sortByDateMessages = data => {
    return data?.sort(function (a, b) {
      return new Date(b?.timeStamp) - new Date(a?.timeStamp)
    })
  }

  const sortByUser = data => {
    return (
      data?.filter(
        item => item?.senderId === user?.id || item?.receiverId === user?.id
      ) || []
    )
  }

  const filtered = (key, value) => {
    handleChange(key, value)
    if (value) {
      const re = new RegExp(value, 'i')
      var filtered = allList?.filter(entry =>
        Object.values(
          entry?.senderId == user?.id ? entry?.receiver : entry?.sender
        ).some(val => typeof val === 'string' && val.match(re))
      )
      handleChange('List', filtered)
    } else {
      handleChange('List', allList)
    }
  }

  // In next two statements we define the state needed for our chat
  const [input, setInput] = useState('')
  const [members, setMembers] = useState(null)

  // First we need to set our PubNub UUID and subscribe to chat channel.
  // We will use `useEffect` hook for that.
  const handleSubmit = () => {
    if (input?.trim()) {
      // Create the message with random `id`.
      const data = {
        text: input,
        timeStamp: Date.now(),
        type: 'text',
        senderId: user?.id
      }
      let messages = state.messages.concat(data)
      const values = {
        messages,
        senderRead:
          state?.messageData?.senderRead > 0
            ? Number(state.messageData.senderRead) + 1
            : 1,
        receiverRead:
          state?.messageData?.receiverRead > 0
            ? Number(state.messageData.receiverRead) + 1
            : 1
      }

      database
        .ref('Messages/' + currentChatId)
        .update(values)
        .then(res => {
          setInput('')
          divRef.current?.scrollIntoView({
            behavior: 'smooth'
          })
          // downButtonHandler()
        })
        .catch(err => {
          console.log(err)
          // Toast.show('Something went wrong!', Toast.LONG)
        })
      // const message = {
      //   content: input,
      //   id: Math.random()
      //     .toString(16)
      //     .substr(2)
      // }

      // // Publish our message to the channel `chat`
      // // pubnub.publish({ channel: chatId, message })
      // const filteredChat = conversations?.conversations?.filter(
      //   e =>
      //     Number(e?.company?.id) === Number(chatUserId) ||
      //     Number(e?.candidate?.id) === Number(chatUserId)
      // )
      const sendBody = {
        user_id:
          state?.messageData?.senderId === user?.id
            ? state?.messageData?.receiverId
            : state?.messageData?.senderId
      }
      // const payload = {
      //   channel_id: chatId
      // }
      // const body = {
      //   id: sendBody.conversation,
      //   payload
      // }
      // // console.log('sendBody', sendBody)

      // dispatch(updateConversationsRequest(body))
      dispatch(sendMessageRequest(sendBody))

      // Clear the input field.
      // setInput('')
    }
  }

  const readMessages = async () => {
    const filteredChat = conversations?.conversations?.filter(
      e =>
        Number(e?.company?.id) === Number(chatUserId) ||
        Number(e?.candidate?.id) === Number(chatUserId)
    )
    const sendBody = {
      conversation: filteredChat && filteredChat[0]?.id
    }

    // console.log('sendBody', sendBody)

    const res = await dispatch(readMessageRequest(sendBody))
    if (res?.type === 'auth/readMessageRequest/fulfilled') {
      dispatch(conversationsRequest())
      startChatComponentes()
    }
  }

  return (
    <WrapperContent label='Messages'>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          bgcolor: '#FFF',
          borderRadius: '15px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Box
              sx={{
                width: leftSideSize,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box
                sx={{
                  height: '70px',
                  borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                }}
              ></Box>
              <Box
                sx={{
                  // LEFT CONTENT
                  // bgcolor: 'red',
                  flexGrow: 1,
                  borderRight: '1px solid rgba(0, 0, 0, 0.1)'
                }}
              >
                <Box>
                  <UITextField
                    id='search'
                    icon={<SearchIcon />}
                    value={searchText}
                    onChange={t => {
                      filtered('searchText', t)
                    }}
                    sx={{
                      p: 2,
                      py: 2,
                      input: {
                        borderRadius: '24px'
                      }
                    }}
                    sxi={{
                      borderRadius: '24px'
                    }}
                  />
                </Box>
                {sortByDate(List)?.map(item => {
                  return (
                    <UserItem
                      key={item?.id}
                      title={
                        item?.senderId === user?.id
                          ? item?.receiver?.first_name +
                            ' ' +
                            item?.receiver?.last_name
                          : item?.sender?.first_name +
                            ' ' +
                            item?.sender?.last_name
                      }
                      unread={
                        item?.senderId === user?.id
                          ? item?.senderRead
                          : item?.receiverRead
                      }
                      subtitle={
                        item?.senderId === user?.id
                          ? item?.receiver?.company?.position ||
                            item?.receiver?.candidate
                              ?.current_company_position ||
                            'Unemployed'
                          : item?.sender?.company?.position ||
                            item?.sender?.candidate?.current_company_position ||
                            'Unemployed'
                      }
                      photo={
                        item?.senderId === user?.id
                          ? item?.receiver?.company?.photo ||
                            item?.receiver?.candidate?.photo
                          : item?.sender?.company?.photo ||
                            item?.sender?.candidate?.photo
                      }
                      // onClick={() => setShowDetail((s) => !s)}
                      onClick={() => {
                        checkDeleted(
                          item?.senderId === user?.id
                            ? item?.receiverId
                            : item?.senderId
                        )
                        navigate(
                          `/app/chat/${
                            item?.senderId === user?.id
                              ? item?.receiverId
                              : item?.senderId
                          }/${item?.id}`
                        )
                        // readMessages()
                        setCurrentChatId(item?.id)
                        setShowDetail(true)
                      }}
                      selected={item?.id == currentChatId}
                    />
                  )
                })}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
              }}
            >
              <Box
                sx={{
                  height: '70px',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row'
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  {chatUserId !== 0 && (
                    <UserDisplay
                      title={
                        isDelete
                          ? 'User Deleted'
                          : state.messageData?.senderId === user?.id
                          ? state.messageData?.receiver?.first_name +
                            ' ' +
                            state.messageData?.receiver?.last_name
                          : state.messageData?.sender?.first_name +
                            ' ' +
                            state.messageData?.sender?.last_name
                      }
                      subtitle={
                        !isDelete
                          ? state.messageData?.senderId === user?.id
                            ? state.messageData?.receiver?.company?.position ||
                              state.messageData?.receiver?.candidate
                                ?.current_company_position ||
                              'Unemployed'
                            : state.messageData?.sender?.company?.position ||
                              state.messageData?.sender?.candidate
                                ?.current_company_position ||
                              'Unemployed'
                          : ''
                      }
                      photo={
                        state.messageData?.senderId === user?.id
                          ? state.messageData?.receiver?.company?.photo ||
                            state.messageData?.receiver?.candidate?.photo
                          : state.messageData?.sender?.company?.photo ||
                            state.messageData?.sender?.candidate?.photo
                      }
                      // subtitle="Typing..."
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  // RIGHT CONTENT - CHANNEL LIST
                  // bgcolor: 'pink',
                  display: 'flex',
                  flexDirection: 'column',
                  // flexGrow: 1,
                  // height: '100%',
                  height: '60vh',
                  justifyContent: 'space-between',
                  overflowY: 'hidden',
                  position: 'relative'
                }}
              >
                {!isDelete && (
                  <UIMessageList
                    messages={sortByDateMessages(messages)}
                    uuid={user?.id}
                    divRef={divRef}
                    messageData={state.messageData}
                    photo={
                      state.messageData?.senderId === user?.id
                        ? state.messageData?.receiver?.company?.photo ||
                          state.messageData?.receiver?.candidate?.photo
                        : state.messageData?.sender?.company?.photo ||
                          state.messageData?.sender?.candidate?.photo
                    }
                  />
                )}
              </Box>
              <UIMessageInput
                placeholder='Type something to send...'
                onChange={setInput}
                value={input}
                onKeyPress={event => {
                  // console.log('event: ', event);
                  if (event.key === 'Enter') {
                    if (
                      user?.type !== 'Candidate' &&
                      (currentUser?.subscribed == 'canceled' ||
                        !currentUser?.subscription)
                    ) {
                    } else {
                      handleSubmit()
                    }
                  }
                }}
                icon={
                  <IconButton
                    disabled={
                      user?.type !== 'Candidate'
                        ? currentUser?.subscribed == 'canceled' ||
                          !currentUser?.subscription
                        : false
                    }
                    onClick={handleSubmit}
                    sx={{ p: 0 }}
                  >
                    <SendMsgIcon />
                  </IconButton>
                }
              />
            </Box>
          </Box>
        </Box>
        <Collapse
          orientation='horizontal'
          in={showDetail}
          sx={{
            //
            display: 'flex',
            borderLeft: '1px solid rgba(0, 0, 0, 0.1)'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              width: leftSideSize
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
                p: 2,
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
              }}
            >
              <IconButton
                aria-label='close'
                sx={{
                  right: 0,
                  top: 0,
                  m: 2,
                  width: '32px',
                  height: '32px',
                  position: 'absolute',
                  // boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
                  backgroundColor: 'rgba(196, 196, 196, 0.3)'
                }}
                onClick={() => setShowDetail(false)}
              >
                <CloseIcon height='10' width='10' />
              </IconButton>
              <Avatar
                sx={{
                  width: '86px',
                  height: '86px',
                  my: 3
                }}
                src={
                  state.messageData?.senderId === user?.id
                    ? state.messageData?.receiver?.company?.photo ||
                      state.messageData?.receiver?.candidate?.photo
                    : state.messageData?.sender?.company?.photo ||
                      state.messageData?.sender?.candidate?.photo
                }
              />
              <Typography
                sx={{
                  //
                  fontWeight: '500',
                  fontSize: '18px',
                  textAlign: 'center'
                }}
              >
                {isDelete
                  ? 'User Deleted'
                  : state.messageData?.senderId === user?.id
                  ? state.messageData?.receiver?.first_name +
                    ' ' +
                    state.messageData?.receiver?.last_name
                  : state.messageData?.sender?.first_name +
                    ' ' +
                    state.messageData?.sender?.last_name}
              </Typography>
              <Typography
                sx={{
                  //
                  fontWeight: '400',
                  fontSize: '14px',
                  textAlign: 'center',
                  color: 'rgba(51, 51, 51, 0.5)'
                }}
              >
                {isDelete
                  ? ''
                  : state.messageData?.senderId === user?.id
                  ? state.messageData?.receiver?.company?.position ||
                    state.messageData?.receiver?.candidate
                      ?.current_company_position ||
                    'Unemployed'
                  : state.messageData?.sender?.company?.position ||
                    state.messageData?.sender?.candidate
                      ?.current_company_position ||
                    'Unemployed'}
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography
                sx={{
                  //
                  fontWeight: '400',
                  fontSize: '12px',
                  color: 'rgba(51, 51, 51, 0.5)'
                }}
              >
                Contact Number
              </Typography>
              <Typography
                sx={{
                  //
                  fontWeight: '400',
                  fontSize: '16px'
                  // color: 'rgba(51, 51, 51, 0.5)',
                }}
              >
                {!isDelete &&
                  formatPhoneNumber(
                    state.messageData?.senderId === user?.id
                      ? state.messageData?.receiver?.company?.contact_number ||
                          state.messageData?.receiver?.candidate
                            ?.contact_number ||
                          'Unemployed'
                      : state.messageData?.sender?.company?.contact_number ||
                          state.messageData?.sender?.candidate
                            ?.contact_number ||
                          'Unemployed'
                  )}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <UIButton
                  color='primary'
                  sx={{
                    //
                    // width: '334px',
                    // width: '100%',
                    py: 1,
                    fontSize: '0.75rem' //12px
                    // color: 'white',
                  }}
                  disabled={
                    isDelete ||
                    (user?.type !== 'Candidate'
                      ? currentUser?.subscribed == 'canceled' ||
                        !currentUser?.subscription
                      : false)
                  }
                  onClick={() => {
                    navigate(`/app/profile/${msgId}`)
                  }}
                >
                  View Profile
                </UIButton>
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </WrapperContent>
  )
}

const UIMessageItem = ({
  channel, // Channel on which the message was published --<<
  subscription, // Channel group or wildcard subscription match (if exists)
  timetoken, // Publish timetoken --<<
  message, // Message payload
  senderId, // Message senderId
  receiverId,
  text,
  myUuid,
  photo
}) => {
  // const { content, id } = message || {}
  const isMyself = (senderId || receiverId) === myUuid
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMyself ? 'row-reverse' : 'row'
      }}
    >
      {!isMyself && (
        <Avatar
          src={photo}
          sx={{
            width: '52px',
            height: '52px',
            ml: 2
          }}
        />
      )}
      <Box
        sx={{
          //
          // bgcolor: 'blu/e',
          bgcolor: isMyself ? 'secondary.main' : 'rgba(196, 196, 196, 0.1)',
          color: isMyself ? '#FFF' : '#000',
          // height: '40px',
          p: 2,
          mb: 2,
          mx: 2,
          maxWidth: '60%',
          borderRadius: isMyself ? '10px 10px 0px 10px' : '10px 10px 10px 0px'
        }}
      >
        {text}
      </Box>
    </Box>
  )
}

const UIMessageList = ({
  messages = [],
  uuid,
  photo,
  messageData,
  divRef,
  ...props
}) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column-reverse',
        overflowY: 'auto'
      }}
    >
      <div id={'el'} ref={divRef}></div>
      {messages.map(m => (
        <UIMessageItem
          key={m?.timeStamp}
          myUuid={uuid}
          {...messageData}
          photo={photo}
          {...m}
        />
      ))}
    </Box>
  )
}
const UIMessageInput = ({ ...props }) => {
  return (
    <Box sx={{ mx: 2 }}>
      <UITextField
        sx={
          {
            //
            // flexDirection: 'row-reverse',
          }
        }
        id='send'
        {...props}
      />
    </Box>
  )
}

const UserDisplay = ({ title, subtitle, photo }) => {
  return (
    <>
      <Avatar
        sx={{
          width: '50px',
          height: '50px',
          mx: 2
        }}
        src={photo}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          // justifyContent: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <Typography
          sx={{
            //
            fontWeight: '500',
            fontSize: '18px'
            // textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            //
            fontWeight: '400',
            fontSize: '14px',
            // textAlign: 'center',
            color: 'rgba(51, 51, 51, 0.5)'
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </>
  )
}

const UserItem = ({
  title,
  subtitle,
  selected,
  unread,
  photo,
  onClick = () => {}
}) => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        p: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        bgcolor: selected ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
        borderRight: `4px solid ${selected ? '#000' : 'transparent'}`,
        py: 2
      }}
    >
      <UserDisplay title={title} subtitle={subtitle} photo={photo} />
      {unread > 0 && (
        <Avatar
          sx={{
            position: 'absolute',
            width: '20px',
            height: '20px',
            fontSize: '0.8rem',
            fontWeight: '500',
            right: 0,
            mr: 1,
            bgcolor: 'secondary.main',
            color: '#000'
          }}
        >
          M
        </Avatar>
      )}
    </ButtonBase>
  )
}

export default PageChat
