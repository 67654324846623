import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { ReactComponent as JobPostedIcon } from '@assets/icons/jobPosted.svg'
import UIButton from '@components/UIButton'
import UICard from '@components/UICard'

const PageConcratulationJob = () => {
  const navigate = useNavigate()
  return (
    <UICard
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 3
        }}
      >
        <JobPostedIcon />
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '1.75rem',
            textAlign: 'center',
            my: 3
          }}
        >
          Congratulations!
          <br />
          Your post is created successfully.
        </Typography>
        <UIButton
          color='primary'
          sx={{
            color: 'white',
            width: '334px'
          }}
          onClick={() => navigate('/app/jobs')}
        >
          See Posts
        </UIButton>
      </Box>
    </UICard>
  )
}

export default PageConcratulationJob
