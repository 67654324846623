import * as React from 'react'
import { useIMask } from 'react-imask'

import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'

import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import { alpha, styled } from '@mui/material/styles'
import FormHelperText from '@mui/material/FormHelperText'
import Select from '@mui/material/Select'
import { deepPurple } from '@mui/material/colors'

const RedditTextField = styled(props => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 10,
    // backgroundColor: theme.palette.mode === 'light' ? '#F6F6F6' : '#F6F6F6',
    backgroundColor: '#F6F6F6',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    input: {
      marginLeft: theme.spacing(1)
    },
    '&:hover': {
      // backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      // backgroundColor: 'transparent',
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      // borderColor: theme.palette.primary.main,
      // border: '1px solid #e2e2e1',
    }
  }
}))

const UIInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: '#F6F6F6',
  borderRadius: 10,
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiOutlinedInput-input': {
    backgroundColor: '#F6F6F6',
    '-moz-appearance': 'textfield', // hide input type numbers arrows (on firefox)
    '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
}))

const UISelect = styled(Select)(({ theme }) => ({
  // custom style
}))

export const UITextFieldMask = ({ opts, onChange = () => {}, ...props }) => {
  // const [opts, setOpts] = React.useState({ mask: '(000) 000-0000' });
  const { ref, maskRef } = useIMask(opts)
  function handleChange (value) {
    onChange(maskRef.current.unmaskedValue)
  }

  return <UITextField onChange={handleChange} inputRef={ref} {...props} />
}

const UITextField = ({
  children,
  label,
  icon,
  sx = {},
  sxi = {},
  id,
  error,
  value,
  inputRef,
  select = false,
  multiple = false,
  onChange = () => {},
  ...props
}) => {
  let inputProps = sxi
  if (props?.type === 'password' && value) {
    inputProps.fontFamily = 'Verdana'
    inputProps.letterSpacing = '0.125em'
  }
  if (error) {
    console.log('[dev] UITextField::error::', error)
  }
  function handleChange (event) {
    // console.log(
    //   `[dev] UITextField::handleChange::value::${id}::${event?.target?.value}::`
    // );
    onChange(event?.target?.value)
  }
  const inputComponent = (
    <UIInput
      id={id}
      startAdornment={<InputAdornment position='start'>{icon}</InputAdornment>}
      variant='filled'
      sx={inputProps}
      value={value}
      error={!!error}
      inputRef={inputRef}
      onChange={handleChange}
      {...props}
    />
  )
  const menuProps = {
    classes: {
      paper: {
        // borderRadius: 12,
        marginTop: 8
      }
    },
    MenuListProps: {
      // disableListWrap: false,
      sx: {
        display: 'flex',
        flexDirection: 'column',
        // borderRadius: '8px',
        py: 0,
        '& li': {
          fontWeight: 200,
          py: '12px'
        }
      }
    }
    // anchorOrigin: {
    //   vertical: 'bottom',
    //   horizontal: 'left',
    // },
    // transformOrigin: {
    //   vertical: 'top',
    //   horizontal: 'left',
    // },
    // getContentAnchorEl: null,
  }
  return (
    // <RedditTextField
    //   label={label}
    //   // defaultValue="react-reddit"
    //   id={id}
    //   variant="outlined"
    //   // variant="filled"
    //   // variant="standard"
    //   sx={sx}
    //   value={value}
    //   error={!!error}
    //   inputRef={inputRef}
    //   onChange={handleChange}
    //   helperText={error}
    //   InputProps={{
    //     startAdornment: icon,
    //     sx: inputProps,
    //   }}
    //   {...props}
    //   // style={{ marginTop: 11 }}
    // />
    <FormControl variant='standard' fullWidth sx={sx}>
      {label && (
        <InputLabel htmlFor={id} sx={{ color: 'rgba(0, 0, 0, 0.5)' }}>
          {label}
        </InputLabel>
      )}
      {(select && (
        <UISelect
          labelId={id}
          id={id}
          multiple={multiple}
          onChange={handleChange}
          // displayEmpty
          inputProps={{
            //
            id
            // fullwidth: 'true',
          }}
          input={inputComponent}
          {...props}
          MenuProps={menuProps}
        >
          {children}
        </UISelect>
      )) ||
        inputComponent}
      <FormHelperText sx={{ color: 'error.main' }}>
        {error?.message || ''}
      </FormHelperText>
    </FormControl>
  )
}
export default UITextField
