import * as React from 'react'
import UIButton, {
  SocialButtonFacebook,
  SocialButtonGmail
} from '@components/UIButton'
import { useNavigate, useParams, NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'

import UITextField, { UITextFieldMask } from '@components/UITextField'

import { ReactComponent as ProfileIcon } from '@assets/icons/profile.svg'
import { ReactComponent as MessageIcon } from '@assets/icons/message.svg'
import { ReactComponent as LockIcon } from '@assets/icons/lock.svg'
import { ReactComponent as CallIcon } from '@assets/icons/call.svg'
import { ReactComponent as SuitcaseIcon } from '@assets/icons/suitcase.svg'
import { ReactComponent as TeamIcon } from '@assets/icons/team.svg'
import { ReactComponent as CreditcardIcon } from '@assets/icons/creditcard.svg'
import { ReactComponent as PaypalIcon } from '@assets/icons/paypal.svg'

import { ReactComponent as GoogleIcon } from '@assets/icons/google.svg'
import { ReactComponent as FacebookIcon } from '@assets/icons/facebook.svg'
import {
  selectIndustries,
  selectPositions,
  selectToken,
  signupRequest,
  socialAuthRequest
} from '@features/auth/authSlice'
import { ReactComponent as SetupProfileSvg } from '@assets/icons/imgProfile.svg'

import UIRadio from '@components/UIRadio'
import { Checkbox, Grid, MenuItem } from '@mui/material'
import { Config } from '@utils/common'
import { unwrapResult } from '@reduxjs/toolkit'
import { updateSnack } from '@features/util/utilSlice'

// curl --location --request POST 'https://ideapros-llc-lander-30616.botics.co/api/v1/user/' \
// --form 'email="sallar.rezaie@crowdbotics.com"' \
// --form 'password="testpass123"' \
// --form 'first_name="Sallar"' \
// --form 'last_name="Rezaie"' \
// --form 'type="Candidate"' \
// --form 'candidate.contact_number="4169944347"'
const mapType = {
  candidate: 'Candidate',
  company: 'Company'
}

const fieldValidationSchema = yup.object().shape({
  first_name: yup.string().required('Please enter your first name'),
  last_name: yup.string().required('Please enter your last name'),
  email: yup
    .string()
    .required('Please enter your email address')
    .email('Please enter a valid email address'),
  password: yup
    .string()
    .required('Please enter your password')
    .min(6),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
  contact_number: yup.string().required('Contact Number is required'),
  candidate: yup.object().shape({
    currently_employed: yup.string(),
    current_company_name: yup.string(),
    current_company_industry: yup.string(),
    current_company_position: yup.string()
  }),
  company: yup.object().shape({
    business_name: yup.string(),
    business_type: yup.string(),
    position: yup.string()
    //[payment] payment_method: yup.string(),
  }),
  isChecked: yup
    .boolean()
    .required('Please agree terms and condition and privacy policy')
})

const Divider = () => (
  <Box
    sx={{
      height: '1px',
      width: '120px',
      backgroundColor: '#33333320',
      mx: 5
    }}
  />
)

const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirmPassword: '',
  contact_number: '',
  candidate: {
    currently_employed: '',
    current_company_name: '',
    current_company_industry: '',
    current_company_position: ''
  },
  company: {
    business_name: '',
    business_type: '',
    position: ''
    //[payment] payment_method: '',
  },
  isChecked: false
}

const PageRegister = () => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    // setError,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(fieldValidationSchema)
  })
  const dispatch = useDispatch()
  const token = useSelector(selectToken)
  const [isCheckedType, setIsCheckedType] = React.useState(false)
  const positions = useSelector(selectPositions)
  const industries = useSelector(selectIndustries)
  const params = useParams()
  const navigate = useNavigate()
  const userType = params?.userType || 'candidate' // candidate | manager

  React.useEffect(() => {
    setValue('first_name', defaultValues.first_name)
    setValue('last_name', defaultValues.last_name)
    setValue('email', defaultValues.email)
    setValue('password', defaultValues.password)
    setValue('confirmPassword', defaultValues.confirmPassword)
    setValue('contact_number', defaultValues.contact_number)
    setValue('isChecked', defaultValues.isChecked)
    if (userType === 'company') {
      setValue('company.business_name', defaultValues.company.business_name)
      setValue('company.business_type', defaultValues.company.business_type)
      setValue('company.position', defaultValues.company.position)
      //[payment] setValue('company.payment_method', defaultValues.company.payment_method);
    } else {
      setValue(
        'candidate.currently_employed',
        defaultValues.candidate.currently_employed
      )
      setValue(
        'candidate.current_company_name',
        defaultValues.candidate.current_company_name
      )
      setValue(
        'candidate.current_company_industry',
        defaultValues.candidate.current_company_industry
      )
      setValue(
        'candidate.current_company_position',
        defaultValues.candidate.current_company_position
      )
    }
  }, [setValue, userType])
  React.useEffect(() => {
    register('first_name')
    register('last_name')
    register('email')
    register('password')
    register('confirmPassword')
    register('contact_number')
    if (userType === 'company') {
      register('company.business_name')
      register('company.business_type')
      register('company.position')
      //[payment] register('company.payment_method');
    } else {
      register('candidate.currently_employed')
      register('candidate.current_company_name')
      register('candidate.current_company_industry')
      register('candidate.current_company_position')
    }
  }, [register, userType])

  const onChange = key => value => {
    clearErrors(key)
    setValue(key, value)
  }

  const onSubmit = ({
    confirmPassword,
    contact_number,
    candidate,
    company,
    ...data
  }) => {
    if (!getValues('isChecked')) {
      setIsCheckedType(true)
      return
    }
    const body = {
      type: mapType[userType],
      ...data
    }
    if (userType === 'company') {
      body.company = {
        contact_number,
        ...company
      }
    } else {
      const { currently_employed } = candidate
      if (currently_employed === 'True') {
        body.candidate = {
          // currently_employed: true,
          currently_employed,
          contact_number,
          ...candidate
        }
      } else {
        body.candidate = {
          // currently_employed: false,
          currently_employed,
          contact_number
        }
      }
    }
    dispatch(signupRequest(body))
      .then(unwrapResult)
      .then(data => {
        console.log('[dev]PageLogin::data', data)
      })
      .catch(data => {
        console.log('dataerrr', data)
        if (data?.email) {
          dispatch(
            updateSnack({
              open: true,
              severity: 'error',
              message: data?.email
            })
          )
        }
        console.log('[dev]PageLogin::catch::', data)
      })
  }
  if (token) {
    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 3
        }}
      >
        <SetupProfileSvg />
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '1.75rem',
            textAlign: 'center',
            my: 3
          }}
        >
          Congratulations!
          <br />
          Your account is successfully created
        </Typography>
        <UIButton
          color='primary'
          sx={{ width: '356px', my: 1 }}
          onClick={() => navigate('/setup')}
        >
          Set up profile
        </UIButton>
      </Box>
    )
  }

  const handleSocialLogin = user => {
    if (!getValues('isChecked')) {
      setIsCheckedType(true)
      return
    }
    // "facebook"
    const { _token, _profile, _provider } = user || {}
    const { accessToken } = _token || {}
    const data = {
      access_token: accessToken,
      provider: _provider,
      type: mapType[userType]
    }
    console.log('[dev] handleSocialLogin::data::', data)
    dispatch(socialAuthRequest(data))
  }

  const handleSocialLoginFailure = err => {
    console.error('[dev] handleSocialLoginFailure::err::', err)
  }

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

  return (
    <Box
      sx={{
        py: 3,
        maxWidth: '543px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        sx={{
          //
          fontWeight: '500',
          fontSize: '28px',
          textAlign: 'center',
          my: 2
        }}
      >
        Create a new account
      </Typography>
      <Box
        component='form'
        noValidate
        sx={{
          display: 'grid',
          gridTemplateColumns: { sm: '1fr 1fr' },
          gap: 2
        }}
      >
        <UITextField
          id='first_name'
          label='First Name'
          placeholder='First Name'
          icon={<ProfileIcon />}
          onChange={onChange('first_name')}
          error={errors?.first_name}
        />
        <UITextField
          id='last_name'
          label='Last Name'
          placeholder='Last Name'
          icon={<ProfileIcon />}
          onChange={onChange('last_name')}
          error={errors?.last_name}
        />
        <UITextField
          id='email'
          label='Enter email address'
          placeholder='Enter your email address'
          icon={<MessageIcon />}
          sx={{ gridColumn: 'span 2' }}
          onChange={onChange('email')}
          error={errors?.email}
        />
        <UITextField
          id='password'
          label='Create Password'
          placeholder='Create Password'
          icon={<LockIcon />}
          type='password'
          onChange={onChange('password')}
          value={getValues('password')}
          error={errors?.password}
        />
        <UITextField
          id='confirmPassword'
          label='Confirm Password'
          placeholder='Confirm Password'
          icon={<LockIcon />}
          type='password'
          onChange={onChange('confirmPassword')}
          value={getValues('confirmPassword')}
          error={errors?.confirmPassword}
        />
        {(userType === 'company' && (
          <>
            <UITextField
              id='company.business_name'
              label='Business Name'
              placeholder='Business Name'
              icon={<SuitcaseIcon />}
              onChange={onChange('company.business_name')}
              value={getValues('company.business_name')}
              error={errors?.company?.business_name}
            />
            <UITextFieldMask
              id='contact_number'
              label='Business Number'
              placeholder='Enter Business Number'
              icon={<CallIcon />}
              onChange={onChange('contact_number')}
              error={errors?.contact_number}
              opts={{ mask: '(000) 000-0000' }}
              // sx={{ gridColumn: 'span 2' }}
            />
            <UITextField
              select
              label='Industry'
              id='company.business_type'
              icon={<SuitcaseIcon />}
              onChange={onChange('company.business_type')}
              value={getValues('company.business_type')}
              error={errors?.company?.business_type}
            >
              {industries?.map(({ name }) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </UITextField>
            <UITextField
              select
              label='Your Position'
              id='company.position'
              icon={<TeamIcon />}
              onChange={onChange('company.position')}
              value={getValues('company.position')}
              error={errors?.company?.position}
            >
              {positions?.map(({ name }) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </UITextField>
          </>
        )) || (
          <>
            <UITextFieldMask
              id='contact_number'
              label='Contact Number'
              placeholder='Enter contact number'
              icon={<CallIcon />}
              onChange={onChange('contact_number')}
              error={errors?.contact_number}
              opts={{ mask: '(000) 000-0000' }}
              // sx={{ gridColumn: 'span 2' }}
            />
            <UIRadio
              id='candidate.currently_employed'
              label='Are you currently employed?'
              options={[
                { label: 'Yes', value: 'True' },
                { label: 'No', value: 'False' }
              ]}
              onChange={onChange('candidate.currently_employed')}
              value={getValues('candidate.currently_employed')}
              error={errors?.candidate?.currently_employed}
            />
            <Collapse
              in={getValues('candidate.currently_employed') === 'True'}
              sx={{ gridColumn: 'span 2' }}
            >
              <Box
                sx={{
                  gridColumn: 'span 2',
                  display: 'grid',
                  gridTemplateColumns: { sm: '1fr 1fr' },
                  gap: 2
                }}
              >
                <UITextField
                  id='candidate.current_company_name'
                  label='Company Name'
                  placeholder='Current Company Name'
                  icon={<SuitcaseIcon />}
                  onChange={onChange('candidate.current_company_name')}
                  value={getValues('candidate.current_company_name')}
                  error={errors?.candidate?.current_company_name}
                  sx={{ gridColumn: 'span 2' }}
                />
                <UITextField
                  select
                  label='Industry'
                  id='candidate.current_company_industry'
                  icon={<SuitcaseIcon />}
                  onChange={onChange('candidate.current_company_industry')}
                  value={getValues('candidate.current_company_industry')}
                  error={errors?.candidate?.current_company_industry}
                >
                  {industries?.map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </UITextField>
                <UITextField
                  select
                  label='Your Position'
                  id='candidate.current_company_position'
                  icon={<TeamIcon />}
                  onChange={onChange('candidate.current_company_position')}
                  value={getValues('candidate.current_company_position')}
                  error={errors?.candidate?.current_company_position}
                >
                  {positions?.map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </UITextField>
              </Box>
            </Collapse>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
          // gridColumn: 'span 2',
        }}
      >
        <UIButton
          color='primary'
          sx={{ width: '334px', my: 4 }}
          onClick={handleSubmit(onSubmit)}
        >
          Review & Submit
        </UIButton>
        {isCheckedType && (
          <Typography sx={{ color: 'error.main' }}>
            {'Please agree to terms and conditions and privacy policy'}
          </Typography>
        )}
        <Grid className='rowCenter'>
          <Checkbox
            {...label}
            onChange={value => {
              setIsCheckedType(false)
              onChange('isChecked')(value.target.checked)
            }}
            checked={getValues('isChecked')}
          />

          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '0.875rem',
              color: '#00000050',
              px: 4,
              textAlign: 'center'
            }}
          >
            By continuing, you're confirming that you've read and agree to our{' '}
            <a
              href='https://www.trylander.com/terms-and-conditions'
              target={'_blank'}
              style={{ color: '#000', textDecoration: 'underline' }}
            >
              Terms and Conditions
            </a>
            ,{' and '}
            <a
              href='https://www.trylander.com/privacy-policy'
              target={'_blank'}
              style={{ color: '#000', textDecoration: 'underline' }}
            >
              Privacy Policy
            </a>
            {/* {' and '}
            <a
              href='https://www.trylander.com/privacy-policy'
              target={'_blank'}
              style={{ color: '#000', textDecoration: 'underline' }}
            >
              Cookies Policy
            </a> */}
          </Typography>
        </Grid>

        {/* <Box
          sx={{
            display: 'flex',
            gridColumn: 'span 2',
            alignItems: 'center',
            my: 4
          }}
        >
          <Divider />
          <Typography sx={{ opacity: 0.5 }}>Or</Typography>
          <Divider />
        </Box> */}
      </Box>
      {/* <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { sm: '1fr 1fr' },
          gap: 2
        }}
      >
        <SocialButtonFacebook
          provider='facebook'
          appId={Config.APPID_FACEBOOK}
          onLoginSuccess={handleSocialLogin}
          // triggerLogin={w => console.log('ggggggg', w)}
          onLoginFailure={handleSocialLoginFailure}
          variant='outlined'
          bg='#3B72FF'
          startIcon={<FacebookIcon sx={{ alignSelf: 'flex-start' }} />}
        >
          Continue with Facebook
        </SocialButtonFacebook>
        <SocialButtonGmail
          provider='google'
          appId={Config.APPID_GOOGLE}
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
          variant='outlined'
          // color="#FFFFFF"
          startIcon={<GoogleIcon sx={{ alignSelf: 'flex-start' }} />}
        >
          Continue with Google
        </SocialButtonGmail>
      </Box> */}
    </Box>
  )
}
export default PageRegister
