import * as React from 'react'
import UIButton from '@components/UIButton'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  CircularProgress
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import WrapperContent from '@components/WrapperContent'
import VISA from '../../assets/images/visa.png'
import mastercard from '../../assets/images/mastercard.png'
import {
  candidatesRequest,
  selectCandidates
} from '@features/candidates/candidateSlice'
import UITextField from '@components/UITextField'
// import { useStripe, CardField } from '@stripe/stripe-js'
import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { getPaymentMethod, _addPayMethod } from '@features/price/priceSlice'
import UIDialog from '@components/UIDialog'

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true
}

const Payment = () => {
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const [state, setState] = useState({
    paymentMethod: [],
    selectedValue: '',
    loading: false,
    headingText: '',
    modalText: '',
    modalOpen: false
  })
  const {
    paymentMethod,
    name,
    loading,
    cardDetails,
    selectedValue,
    modalOpen,
    modalText,
    headingText
  } = state
  React.useEffect(() => {
    _getPaymentMethod()
  }, [dispatch])

  const _getPaymentMethod = async () => {
    const res = await dispatch(getPaymentMethod())
    handleChange('paymentMethod', res.payload?.data)
  }

  const handleModalClose = () => {
    handleChange('modalOpen', false)
    handleChange('modalText', '')
    handleChange('headingText', '')
    navigate(-1)
  }

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handlePayment = async () => {
    try {
      handleChange('loading', true)
      console.warn('cardDetails', cardDetails)
      const cardElement = elements.getElement(CardElement)
      stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement
        })
        .then(result => {
          console.warn('result', result?.paymentMethod?.id)
          if (result?.paymentMethod?.id) {
            _AddPayMethod(result?.paymentMethod?.id)
          } else {
            alert(result.error.message)
            handleChange('loading', false)
          }
        })
    } catch (error) {
      handleChange('loading', false)
      console.warn('error', error)
      // const errorText = Object.values(error?.response?.data)
      alert(`Error: ${JSON.stringify(error)}`)
    }
  }

  const _AddPayMethod = async payment_method => {
    try {
      const payload = {
        payment_method
      }
      const res = await dispatch(_addPayMethod(payload))
      handleChange('loading', false)
      _getPaymentMethod()
      handleChange('modalText', 'Card has been added')
      handleChange('headingText', 'SUCCESS')
      handleChange('modalOpen', true)
    } catch (error) {
      handleChange('loading', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText}`)
    }
  }

  return (
    <WrapperContent
      label='Payment'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
      actions={
        <Button
          // onClick={handleOpenMenu}
          style={{
            backgroundColor: '#FF9141',
            color: '#fff',
            width: 150,
            height: 50,
            fontSize: 14,
            textTransform: 'capitalize',
            marginBottom: 20
          }}
        >
          Add Card
        </Button>
      }
    >
      <Paper elevation={0} sx={{ width: '100%', mb: 5, p: 3, borderRadius: 5 }}>
        <Typography variant='h5'>Card Details</Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid item md={6} xs={12} container spacing={3}>
          <Grid item xs={12}>
            <div className='cardBox'>
              <CardElement
                // onChange={cardDetails =>
                //   handleChange('cardDetails', cardDetails)
                // }
                options={CARD_ELEMENT_OPTIONS}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <UITextField
              label={'Card Holder Name'}
              value={name}
              onChange={value => handleChange('name', value)}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid container sx={{ mb: 3 }}>
        <UIButton
          onClick={handlePayment}
          disabled={!name}
          sx={{
            width: '150px',
            height: 50,
            my: 1,
            backgroundColor: 'rgba(30, 33, 32, 1)'
          }}
        >
          {loading ? (
            <CircularProgress
              style={{ color: '#fff', width: 20, height: 20 }}
            />
          ) : (
            'Confirm'
          )}
        </UIButton>
      </Grid>
      <Paper elevation={0} sx={{ width: '100%', mb: 5, p: 3, borderRadius: 5 }}>
        <Typography variant='h5'>List of Cards</Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />
        {paymentMethod?.map((method, index) => (
          <Grid key={index} container alignItems={'center'} sx={{ mb: 1 }}>
            <Radio
              checked={selectedValue === method?.id}
              onChange={event =>
                handleChange('selectedValue', event.target.value)
              }
              value={method?.id}
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
            />
            <img
              src={method?.card?.brand === 'mastercard' ? mastercard : VISA}
              width={40}
              style={{ width: 40, objectFit: 'contain' }}
            />
            <p style={{ marginLeft: 10, marginRight: 20 }}>
              **** **** **** {method?.card?.last4}
            </p>
            <p style={{ color: 'grey' }}>
              Expires {method?.card?.exp_month}/{method?.card?.exp_year}
            </p>
          </Grid>
        ))}
      </Paper>
      <UIDialog
        text={modalText}
        open={modalOpen}
        handleClose={handleModalClose}
        headingText={headingText}
      />
    </WrapperContent>
  )
}
export default Payment
