import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import UITextField from '@components/UITextField'
import { ReactComponent as CheckedIcon } from '@assets/icons/checked.svg'
import UIButton from '@components/UIButton'
import UICard from '@components/UICard'
import { ButtonJobContinue } from './PageJobCreate'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCategories,
  selectNewJob,
  updateJob
} from '@features/jobs/jobSlice'
import { JobCategoryButton, TextSubTitle, TextTitle } from './components'
import { updateSnack } from '@features/util/utilSlice'
// looking_for
// role

const JobSectionCategory = ({ onSubmit, jobDetail }) => {
  const { looking_for, role } = useSelector(selectNewJob)

  const categories = useSelector(selectCategories)
  const dispatch = useDispatch()
  const [roleList, setRoleList] = React.useState(
    categories.find(c => c.name === looking_for)?.subcategories || []
  )
  console.log('jobDetail', jobDetail)
  React.useEffect(() => {
    if (jobDetail) {
      handleChange('looking_for')(jobDetail?.looking_for)
      handleChange('role')(jobDetail?.role)
    }
  }, [jobDetail])

  const handleChange = key => value => {
    dispatch(updateJob({ key, value }))
    dispatch(
      updateSnack({
        open: false
      })
    )
  }
  const handleSubmit = () => {
    if (!looking_for) {
      dispatch(
        updateSnack({
          open: true,
          severity: 'error',
          message: 'Please select what you are looking for'
        })
      )
    } else if (!role) {
      dispatch(
        updateSnack({
          open: true,
          severity: 'error',
          message: 'Please select what you are role'
        })
      )
    } else {
      onSubmit()
    }
  }

  return (
    <>
      <Box>
        <TextTitle>Category</TextTitle>
        <TextSubTitle>I’m looking for</TextSubTitle>
        {categories?.map(({ name, subcategories }) => (
          <JobCategoryButton
            key={name}
            color='primary'
            active={name === looking_for}
            onClick={() => {
              handleChange('looking_for')(name)
              handleChange('role')('')
              setRoleList(subcategories)
            }}
          >
            {name}
          </JobCategoryButton>
        ))}

        <TextSubTitle>Select Category</TextSubTitle>
        {roleList?.map(({ name, id }) => (
          <JobCategoryButton
            key={name}
            color='primary'
            active={id === role || name === role}
            onClick={() => {
              handleChange('role')(name)
            }}
          >
            {name}
          </JobCategoryButton>
        ))}
        {/* <Grid item xs={4}>
          <UITextField
            select
            // label="Your Position"
            id='role'
            // icon={<TeamIcon />}
            onChange={handleChange('role')}
            defaultValue={role || ''}
            // value={getValues('company.position')}
            // error={errors?.company?.position}
          >
            {roleList?.map(({ name }) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </UITextField>
        </Grid> */}
      </Box>
      <ButtonJobContinue onSubmit={handleSubmit} />
    </>
  )
}

export default JobSectionCategory
