import { apiFetch } from '@utils/apiFetch';

const authAPI = apiFetch;

// curl --location --request GET 'https://ideapros-llc-lander-30616.botics.co/api/v1/candidates/active/?interests=&preferred_locations=&position=&city=&state=&zip_code=&name=' \
// --header 'Authorization: Token 6c3a12c373337cf312579e26b224118839d206df'

export function apiCandidateRequest(payload) {
  const {
    candidateType = 'active',
    interests = '',
    roles = '',
    preferedLocations = [],
  } = payload;
  return authAPI.get(
    `/api/v1/candidates/${candidateType}/?interests=${interests}&roles=${roles}&preferred_locations=${preferedLocations.join(
      ','
    )}&position=&city=&state=&zip_code=&name=`
  );
}

// curl --location --request POST 'https://ideapros-llc-lander-30616.botics.co/api/v1/candidates/saved/' \
// --header 'Authorization: Token 6c3a12c373337cf312579e26b224118839d206df' \
// --form 'candidate="19"'
export function apiCandidateSaveRequest(payload) {
  const { save, ...body } = payload;
  if (save) {
    return authAPI.post(`/api/v1/candidates/saved/`, body);
  }
  return authAPI.delete(`/api/v1/candidates/saved/`, body);
}
