import * as React from 'react';

// export const mapType = {
import { selectUser } from '@features/auth/authSlice';
import { useSelector } from 'react-redux';

export const Config = {
  // APPID_FACEBOOK: '270292288017440',
  // APPID_GOOGLE: '1085056740406-4ndmdpuksu69j5vl3at9dqjjn32qgf6n',
  APPID_FACEBOOK: '456607339428430',
  // APPID_GOOGLE: '328721383552-dvhbjdnhi4fon7eiuch5mg7eb178s712',
  APPID_GOOGLE: '328721383552-dvhbjdnhi4fon7eiuch5mg7eb178s712.apps.googleusercontent.com',
};

export const mapToType = {
  candidate: 'Candidate',
  company: 'Company',
};

export const mapFromType = {
  Candidate: 'candidate',
  Company: 'company',
  Employee: 'employee',
};

export const useUserType = () => {
  const user = useSelector(selectUser);
  if (user?.type) {
    return mapFromType[user?.type];
  }

  return undefined;
};

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return [{ anchorEl, setAnchorEl }, handleOpenMenu];
};

export const handleError = ({ data, setError, alertMsg }) => {
  const { type, err, msg } = parseError(data);
  if (type === 'ALERT') {
    // Alert.alert("", "Please, enter a correct value");
    alertMsg(msg);
  } else if (type === 'FORMAT') {
    err.forEach(({ key, val }) => {
      alertMsg(`${key}: ${val}`);
      // setError(key, {
      //   type: 'manual',
      //   message: val,
      // });
    });
  }
  console.log('[dev]handleError::', { type, err, msg });
};

export const parseError = (data) => {
  try {
    if (data?.detail) {
      return {
        type: 'ALERT',
        msg: data?.detail,
      };
    } else {
      const err = getDeepKeys(data);
      return {
        type: 'FORMAT',
        err,
      };
    }
  } catch (err) {
    return {
      type: 'ALERT',
      msg: 'Please, verify your connection',
    };
  }
};
export const checkErr = (data) => {
  const err = data?.non_field_errors;
  // {"non_field_errors":["The OTP you have entered is invalid."]}
  if (
    Array.isArray(err) &&
    err[0]
    // err[0] === "The OTP you have entered is invalid."
    // err[0] === "Your OTP has expired, a new OTP has be sent to your email"
  ) {
    return err[0];
  }
  return '';
};

export const getDeepKeys = (obj) => {
  try {
    if (Array.isArray(obj)) {
      return [
        {
          key: '',
          val: obj.join(', '),
        },
      ];
    } else if (typeof obj === 'string') {
      return [
        {
          key: '',
          val: obj,
        },
      ];
    }
    let keys = [];
    for (let key in obj) {
      const result = getDeepKeys(obj[key]).map((sk) => ({
        key: sk.key ? `${key}.${sk.key}` : key,
        val: sk.val,
      }));
      keys = keys.concat(result);
    }
    return keys;
  } catch (err) {
    console.log('[dev] getDeepKeys::err::', err);
    return [];
  }
};
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}
