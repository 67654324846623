import React from 'react'
import { theme } from '@utils/theme'
import { CssBaseline, ThemeProvider } from '@mui/material'

import './App.css'
import PageIndex from '@screen/PageIndex'
import { loadStripe } from '@stripe/stripe-js'
import UISnackbar from '@components/UISnackbar'
import { Elements } from '@stripe/react-stripe-js'
const stripePromise = loadStripe(
  'pk_live_51Q4oAKFsNovGA9Wa4OqNkMFT6GyXOyaZjAMB37TXCPflmnFhte6tKCzxjspQHVwxxfDeW7WrGQuc0hlPv2O7N2qn00tG4QfVw5'
)
function App () {
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: '{{CLIENT_SECRET}}'
  // }
  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PageIndex />
        <UISnackbar />
      </ThemeProvider>
    </Elements>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <span>
    //       <span>Learn </span>
    //       <a
    //         className="App-link"
    //         href="https://reactjs.org/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         React
    //       </a>
    //       <span>, </span>
    //       <a
    //         className="App-link"
    //         href="https://redux.js.org/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Redux
    //       </a>
    //       <span>, </span>
    //       <a
    //         className="App-link"
    //         href="https://redux-toolkit.js.org/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Redux Toolkit
    //       </a>
    //       ,<span> and </span>
    //       <a
    //         className="App-link"
    //         href="https://react-redux.js.org/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         React Redux
    //       </a>
    //     </span>
    //   </header>
    // </div>
  )
}

export default App
