import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import WrapperMain from '@components/WrapperMain'
import WrapperDashboard from '@components/WrapperDashboard'
import PageHome from './PageHome'
import PageRegister from './PageRegister'
import PageLogin from './PageLogin'
import PageProfileSetup from './profile'

import { useDispatch } from 'react-redux'
import { industriesRequest, positionsRequest } from '@features/auth/authSlice'
import PageCandidates from './candidates/PageCandidates'
import PageJobs from './jobs/PageJobs'
import PageSettings from './settings/PageSettings'
import AccountDeleted from './settings/AccountDeleted'
import Price from './price'
import Faq from './faq'
import PageProfileUser from './profiledetail/PageProfileUser'
import PageJobsCandidate from './jobs/PageJobsCandidate'
import PageChat from './chat/PageChat'
import PageReset from './PageReset'
import AddCard from './price/AddCard'
import Payment from './price/Payment'
import AddUser from './price/AddUser'

export const defaltAppPage = {
  Company: '/app/candidates',
  Candidate: '/app'
}

const PageIndex = () => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(positionsRequest())
    dispatch(industriesRequest())
  }, [dispatch])
  // /Users/doritos/projects/cb/lander/ideapros-llc-lander-30616/frontend/src/screen/PageIndex.js
  return (
    <Routes>
      <Route path='/' element={<WrapperMain />}>
        <Route path='' element={<PageHome />} />
        <Route path='account-deleted' element={<AccountDeleted />} />
      </Route>
      <Route path='/login' element={<WrapperMain />}>
        <Route path='' element={<PageLogin />} />
        <Route
          path='*'
          element={
            <main style={{ padding: '1rem' }}>
              <p>Page Not Found</p>
            </main>
          }
        />
      </Route>
      <Route path='/setup' element={<WrapperMain />}>
        <Route path='' element={<PageProfileSetup />} />
        <Route
          path='*'
          element={
            <main style={{ padding: '1rem' }}>
              <p>Page Not Found</p>
            </main>
          }
        />
      </Route>
      <Route path='/reset' element={<WrapperMain />}>
        <Route path='' element={<PageReset />} />
        <Route
          path='*'
          element={
            <main style={{ padding: '1rem' }}>
              <p>Page Not Found</p>
            </main>
          }
        />
      </Route>
      <Route path='/register' element={<WrapperMain />}>
        <Route path='' element={<PageRegister />}>
          <Route path=':userType' element={<PageRegister />} />
        </Route>
        <Route
          path='*'
          element={
            <main style={{ padding: '1rem' }}>
              <p>Page Not Found</p>
            </main>
          }
        />
      </Route>
      {/* <Route
        path='*'
        element={
          <main style={{ padding: '1rem' }}>
            <p>Page Not Found</p>
          </main>
        }
      /> */}

      <Route path='app' element={<WrapperDashboard />}>
        <Route path='' element={<PageJobsCandidate />} />
        <Route path='candidates' element={<PageCandidates />}>
          <Route path=':section' element={<PageCandidates />} />
        </Route>
        <Route path='jobs' element={<PageJobs />}>
          <Route path=':section' element={<PageJobs />}>
            <Route path=':jobId' element={<PageJobs />} />
          </Route>
        </Route>
        <Route path='profile' element={<PageProfileUser />}>
          <Route path=':profileId' element={<PageProfileUser />} />
        </Route>
        <Route path='settings' element={<PageSettings />} />
        <Route path='saved' element={<PageJobsCandidate saved />} />
        <Route path='price' element={<Price />} />

        <Route path='add-card' element={<Payment />} />
        <Route path='add-user' element={<AddUser />} />
        <Route path='add-user/:id' element={<AddUser />} />
        <Route path='payment/:id' element={<AddCard />} />
        <Route path='faq' element={<Faq />} />
        <Route path='chat' element={<PageChat />}>
          <Route path=':msgId' element={<PageChat />}>
            <Route path=':activeChatId' element={<PageChat />} />
          </Route>
        </Route>
        <Route
          path='*'
          element={
            <main style={{ padding: '1rem' }}>
              <p>Page Not Found</p>
            </main>
          }
        />
      </Route>
    </Routes>
  )
}
export default PageIndex
