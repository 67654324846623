import firebase from 'firebase'
// import 'firebase/auth'
// import 'firebase/database'

const config = {
  apiKey: "AIzaSyADmltgshqP0JcXz-Q10RZMLRyWuwbZDFk",
  databaseURL: 'https://try-lander-default-rtdb.firebaseio.com/',
  authDomain: "try-lander.firebaseapp.com",
  projectId: "try-lander",
  storageBucket: "try-lander.appspot.com",
  messagingSenderId: "342859980858",
  appId: "1:342859980858:web:002424125b365826c89503",
  measurementId: "G-3BEQ4G4TRD"
}



const app = firebase.initializeApp(config)
export const fireBase = firebase
export const database = firebase.database();
export default firebase
