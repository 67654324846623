import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Avatar, Box, IconButton, Typography } from '@mui/material';

import UIButton from '@components/UIButton';
import { ReactComponent as ProfileIcon } from '@assets/icons/profileLarge.svg';
import { updateProfileRequest } from '@features/auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useUserType } from '@utils/common';

const PageUploadImage = ({ onNext }) => {
  const dispatch = useDispatch();
  const userType = useUserType();
  const [currentFile, setCurrentFile] = React.useState();
  const [previewImage, setPreviewImage] = React.useState();

  const selectFile = (event) => {
    const file = event?.target?.files?.[0];
    console.log('[dev] PageUploadImage::selectFile::', file);
    if (file) {
      setCurrentFile(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };
  const handleUpload = () => {
    const formData = new FormData();
    formData.append(`${userType}.photo`, currentFile);
    dispatch(updateProfileRequest(formData))
      .then(unwrapResult)
      .then((data) => {
        console.log('[dev]PageLogin::data', data);
        onNext();
      })
      .catch((data) => {
        console.log('[dev]PageLogin::catch::', data);
      });
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        mt: 3,
      }}
    >
      <label htmlFor="btn-upload">
        <input
          id="btn-upload"
          name="btn-upload"
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          onChange={selectFile}
        />
        <IconButton
          className="btn-choose"
          variant="outlined"
          component="span"
          sx={{
            backgroundColor: '#F0F0F0',
            p: 0,
            border: 'none',
            '&:hover': {
              border: 'none',
            },
          }}
        >
          {(previewImage && (
            <Avatar
              alt="Remy Sharp"
              src={previewImage}
              sx={{ width: 320, height: 320 }}
            />
          )) || <ProfileIcon style={{ margin: '40px' }} />}
        </IconButton>
      </label>

      <Typography
        sx={{
          fontWeight: '500',
          fontSize: '1.75rem',
          textAlign: 'center',
          my: 1,
        }}
      >
        Upload a professional photo
      </Typography>
      <Typography
        sx={{
          fontWeight: '500',
          fontSize: '0.875rem',
          color: '#00000050',
          mb: 2,
          textAlign: 'center',
        }}
      >
        Click on the circle for upload your profile picture
      </Typography>

      <UIButton color="primary" sx={{ width: '356px' }} onClick={handleUpload}>
        Next
      </UIButton>
    </Box>
  );
};
export default PageUploadImage;
