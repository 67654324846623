import * as React from 'react'
import UIButton from '@components/UIButton'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import UITextField, { UITextFieldMask } from '@components/UITextField'
import { ReactComponent as LocationIcon } from '@assets/icons/location.svg'
import { updateProfileRequest } from '@features/auth/authSlice'
import { handleError, useUserType } from '@utils/common'
import { updateSnack } from '@features/util/utilSlice'
import { STATE_TYPES } from '@utils/phoneMask'
import { MenuItem } from '@mui/material'
// import { ReactComponent as SetupProfileSvg } from '@assets/icons/imgProfile.svg';

const fieldValidationSchema = yup.object().shape({
  city: yup.string().required('Please enter your city'),
  zip_code: yup.string().required('Please enter your zip code'),
  state: yup.string().required('Please enter your state')
})

const defaultValues = {
  city: '',
  zip_code: '',
  state: ''
}

const PageLocation = ({ onNext }) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(fieldValidationSchema)
  })
  const dispatch = useDispatch()
  const userType = useUserType()

  React.useEffect(() => {
    setValue('city', defaultValues.city)
    setValue('zip_code', defaultValues.zip_code)
    setValue('state', defaultValues.state)
  }, [setValue])
  React.useEffect(() => {
    register('city')
    register('zip_code')
    register('state')
  }, [register])

  const onChange = key => value => {
    clearErrors(key)
    setValue(key, value)
  }
  const onChangeState = (key, value) => {
    clearErrors(key)
    setValue(key, value)
  }

  const onSubmit = data => {
    const formData = new FormData()

    formData.append(`${userType}.city`, data.city)
    formData.append(`${userType}.zip_code`, data.zip_code)
    formData.append(`${userType}.state`, data.state)
    dispatch(updateProfileRequest(formData))
      .then(unwrapResult)
      .then(data => {
        console.log('[dev]PageLocation::data', data)
        onNext()
      })
      .catch(data => {
        handleError({
          data,
          setError,
          alertMsg: errMsg =>
            dispatch(
              updateSnack({
                open: true,
                severity: 'error',
                message: errMsg
              })
            )
        })
        // if (data?.candidate?.zip_code) {
        //   setError('zip_code', {
        //     type: 'manual',
        //     message: data?.candidate?.zip_code[0],
        //   });
        // }
        // if (data?.detail) {
        //   dispatch(
        //     updateSnack({
        //       open: true,
        //       severity: 'error',
        //       message: data?.detail,
        //     })
        //   );
        // }
        console.log('[dev]PageLocation::catch::', data)
      })
  }

  return (
    <Box
      sx={{
        py: 3,
        width: { sm: '543px' },
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        sx={{
          //
          fontWeight: '500',
          fontSize: '28px',
          textAlign: 'center',
          my: 2
        }}
      >
        Enter your location
      </Typography>
      <Box
        component='form'
        noValidate
        sx={{
          display: 'grid',
          gridTemplateColumns: { sm: '1fr 1fr' },
          gap: 2
        }}
      >
        <UITextField
          id='city'
          label='City'
          placeholder='Enter your City'
          icon={<LocationIcon />}
          onChange={onChange('city')}
          error={errors?.city}
        />
        <UITextFieldMask
          id='zip_code'
          label='Zip Code'
          placeholder='Enter your zip code'
          icon={<LocationIcon />}
          onChange={onChange('zip_code')}
          error={errors?.zip_code}
          opts={{ mask: '00000-0000' }}
        />
        <UITextField
          id='state'
          label='State'
          placeholder='Enter your state'
          icon={<LocationIcon />}
          sx={{ gridColumn: 'span 2' }}
          onChange={onChange('state')}
          value={getValues('state')}
          error={errors?.state}
          select
        >
          <MenuItem value=''>
            <em>Select your state</em>
          </MenuItem>
          {STATE_TYPES.map((state, index) => (
            <MenuItem key={index} value={state}>
              {state}
            </MenuItem>
          ))}
        </UITextField>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
          // gridColumn: 'span 2',
        }}
      >
        <UIButton
          color='primary'
          sx={{ width: '334px', my: 4 }}
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </UIButton>
      </Box>
    </Box>
  )
}
export default PageLocation
